import EmbedController from "../../embed/EmbedController";

export const pushGTMAnalyticsEvent = (dataLayerObj) => {
  if (EmbedController.isEmbedded) {
    EmbedController.sendGTMAnalyticsEvent(dataLayerObj);
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(dataLayerObj);
};
