import { useComponentsIndex } from "../../../hooks/useComponentsIndex";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const Tabs = ({ tabs, componentsStateObj }) => {
  const { materialsIndex } = useComponentsIndex(componentsStateObj.state);

  const handleClick = (tab) => {
    const copyComponentsState = { ...componentsStateObj.state };
    copyComponentsState.array[materialsIndex].material_editing_status = tab._id;

    componentsStateObj.setState(copyComponentsState);

    pushGTMAnalyticsEvent({ event: "Tab Click - MojoDesk", tab: tab.displayName, page: "materials" });
  };

  const tabElements = tabs.map((tab, index) => {
    const isActive = componentsStateObj.state.array[materialsIndex].material_editing_status === tab._id ? "active" : "";
    return (
      <div key={tab._id} className={isActive}>
        <p className={`mb-0 py-2 tab text-center ${isActive}`} onClick={() => handleClick(tab)}>
          {tab.displayName}
        </p>
      </div>
    );
  });

  return <div className="TabsContainer px-2 d-flex mx-auto justify-content-around align-items-center">{tabElements}</div>;
};
