export const ImgWithFallback = ({ src, fallback, type = "image/webp", ...props }) => {
  // if webP doesn't exist, use the fallback
  const handleError = (currentTarget) => {
    currentTarget.onerror = null;
    currentTarget.parentElement.children[0].srcset = fallback;
  };

  return (
    <picture>
      <source srcSet={src} type={type} />
      <img src={fallback} {...props} onError={({ currentTarget }) => handleError(currentTarget)} />
    </picture>
  );
};
