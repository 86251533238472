import { useMemo } from "react";
import sift from "sift";
import { Accordion } from "../Accordion/Accordion";
import { CardElementAccessories } from "../CardElement/CardElementAccessories";

const itemsCache = [];
export const AccessoriesScreen = ({ componentsStateObj, itemsStateObj }) => {
  let defaultOpenAccordion = itemsStateObj.state.activeIds.desk_model._id.includes("mojo_dome");

  const isMonitorActive = useMemo(
    () => itemsStateObj.state.activeIds.accessories.array.find((activeItem) => activeItem._id.includes("monitor")),
    [itemsStateObj.state.activeIds.accessories.array]
  );

  const getItems = (section) => {
    // check if query is already cached
    if (itemsCache[itemsStateObj.state.activeIds.desk_model]) {
      return itemsCache[itemsStateObj.state.activeIds.desk_model._id][section._id];
    }

    // query is not cached, so fetch it, sort it based off of component.items and add it to the cache
    itemsCache[itemsStateObj.state.activeIds.desk_model._id] = {
      ...itemsCache[itemsStateObj.state.activeIds.desk_model._id],
      ...{
        [section._id]: itemsStateObj.state.array
          .filter(
            sift({
              _id: { $in: componentsStateObj.state.activeObj.items },
              section: { $eq: section._id },
              $or: [{ excluded: { $exists: false } }, { excluded: false }],
            })
          )
          .sort((a, b) => {
            return componentsStateObj.state.activeObj.items?.indexOf(a._id) - componentsStateObj.state.activeObj.items?.indexOf(b._id);
          }),
      },
    };
    return itemsCache[itemsStateObj.state.activeIds.desk_model._id][section._id];
  };

  return (
    <div className="mt-3">
      {componentsStateObj.state.activeObj.sections.map((section, index) => {
        // if the section is monitors and the active desk model is a mojo dome, then return null
        if (section._id === "monitors" && (itemsStateObj.state.activeIds.desk_model._id.includes("mojo_dome") || itemsStateObj.state.activeIds.desk_model._id.includes("battle_station"))) return null;
        if (itemsStateObj.state.activeIds.desk_model._id.includes("battle_station")) defaultOpenAccordion = true;
        return (
          <Accordion key={section._id} displayName={section.displayName} _id={section._id} defaultOpen={defaultOpenAccordion}>
            {section?.description && (
              <div className="d-flex flex-column  my-3">
                {!(itemsStateObj.state.activeObjs.desk_model?._id.includes("mojo_dome") || itemsStateObj.state.activeObjs.desk_model?._id.includes("battle_station")) && (
                  <div className="d-flex justify-content-center align-items-center">
                    <img src="/images/iconInfo.svg" alt="info icon" />
                    <h6 className="ms-1 mb-0" style={{ color: "#4F4F4F" }}>
                      {section.description}
                    </h6>
                  </div>
                )}
                {section?.subDescription && isMonitorActive && (
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="ms-1 mb-0" style={{ color: "#4F4F4F" }}>
                      {section.subDescription}
                    </p>
                  </div>
                )}
                {itemsStateObj.state.activeIds.desk_model._id.includes("mojo_dome") && (
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="ms-1 mb-0 text-center" style={{ color: "#4F4F4F" }}>
                      Desktop Fits One 32" Screen or Two 24" Screens. Does not support monitor arms.
                    </p>
                  </div>
                )}
                {section._id === "desk_accessories" && itemsStateObj.state.activeIds.desk_model._id.includes("battle_station") && (
                  <div className="d-flex justify-content-center align-items-center">
                    <img src="/images/iconInfo.svg" alt="info icon" />
                    <p className="ms-1 mb-0 text-center" style={{ color: "#4F4F4F" }}>
                      The following accessories are included in the bundle
                    </p>
                  </div>
                )}
              </div>
            )}
            {/* accessory cards include monitors and general desk accessories */}
            <CardElementAccessories itemsStateObj={itemsStateObj} accessoriesArray={getItems(section)} />
          </Accordion>
        );
      })}
    </div>
  );
};
