import React from "react";
import { DeskModelsScreen } from "./DeskModelsScreen";
import { MaterialsScreen } from "./MaterialsScreen";
import { AccessoriesScreen } from "./AccessoriesScreen";
import { ShoppingCart } from "../ShoppingCart/ShoppingCart";

// maps component _id to screens
const Components = {
  desk_model: DeskModelsScreen,
  materials: MaterialsScreen,
  accessories: AccessoriesScreen,
  shopping_cart: ShoppingCart,
};

export const CreateScreens = ({ componentsStateObj, ...props }) => {
  // make sure we have a valid component coming from components.json
  if (typeof Components[componentsStateObj.state.activeId] !== "undefined") {
    // create and render the component depending on activeObj.react.component
    return React.createElement(Components[componentsStateObj.state.activeId], {
      key: componentsStateObj.state.activeId,
      componentsStateObj,
      ...props,
    });
  }
  // component wasn't found
  return React.createElement(() => <div>The component {componentsStateObj.state.activeId} has not been created yet.</div>, {
    key: Math.floor(Math.random() * 100),
  });
};
