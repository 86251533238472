import EmbedController from "../embed/EmbedController";
import { deviceType, osVersion, osName, fullBrowserVersion, browserName } from "react-device-detect";
import getNetworkSpeed from "@tastetest3d/network-speed";

export default async function AlertSlackOfError(errorSource, errorMessage, sessionId) {
  if (window.location.href.includes("localhost")) return;

  const speed = await getNetworkSpeed({
    url: 'https://network-speed-worker.spatialcinematics.workers.dev/150'
  });

  let alertText = `
  🚨⚠ 
  Source: ${errorSource} 

  Message: ${errorMessage}
  
  Current URL: <${EmbedController?.parentUrl || window.location.href}|here >

  Device Type: ${deviceType}
  Browser Name: ${browserName}
  Browser Version: ${fullBrowserVersion}
  OS Name: ${osName}
  OS Version: ${osVersion}
  Session Id: ${sessionId || "nada"}
  Download Speed: ${speed || "-"} Mbps
  `;

  let alertObj = {
    text: alertText,
  };

  // send POST request to our slack webhook
  const alertResponse = await fetch(process.env.REACT_APP_SLACK_ALERT_WEBHOOK, {
    mode: "no-cors",
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(alertObj),
  });

  // const res = await alertResponse.json();

  // if (res.status == 200)
  //   return true;
  // else
  //   console.log(res);

  return false;
}
