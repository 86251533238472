import { items_state } from "../components/dataManagers/GlobalDataManagers";
import { traverse } from "object-traversal";
import { useAtom } from "jotai";

export function useActiveItem(itemContainerId) {
  const [itemsState] = useAtom(items_state);
  if (!itemsState.activeObjs) return null;

  let item;

  traverse(itemsState.activeObjs, (context) => {
    let { parent, key, value, meta } = context;
    if (key === itemContainerId) {
      item = value;
    }
  });

  return item;
}
