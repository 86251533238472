import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from "prop-types";
import Tooltip from "react-bootstrap/Tooltip";
import { useState, useEffect, useRef } from "react";
import "./styles.scss";

export const CircleButton = ({ icon, circleColor, circleSize = "40px", tooltipContent }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState(false);
  const isTouchDevice = () => "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  const isDefaultShow = tooltipContent?.defaultShow && !isTouchDevice();
  const delayRef = useRef(null);
  const timerRef = useRef(null);
  const defaultShow = () => (delayRef.current = setTimeout(() => setShowTooltip(true), 2500));

  const tooltip = (props) => (
    <Tooltip {...props} id={tooltipContent.id} style={{ zIndex: 1001, ...props.style }}>
      {isTooltipClicked ? tooltipContent.onClick : tooltipContent.onHover}
    </Tooltip>
  );

  useEffect(() => {
    isDefaultShow && document.addEventListener("SceneIsBeingRevealed", defaultShow);

    return () => {
      if (isDefaultShow) {
        document.removeEventListener("SceneIsBeingRevealed", defaultShow);
        clearTimeout(delayRef.current);
      }
    };
  }, []);

  // change back tooltip content after timeout
  useEffect(() => {
    if (isTooltipClicked) {
      timerRef.current = setTimeout(() => {
        setIsTooltipClicked(false);
        setShowTooltip(false);
      }, 5000);
    }
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [isTooltipClicked]);

  // gets called every time user mouse enters or exits
  const overlayHandler = (isMouseEnter) => {
    if (!tooltipContent || isTouchDevice()) return;

    setShowTooltip(isMouseEnter);
  };

  // updates tooltip content when btn clicked
  const onClickHandler = () => {
    if (!tooltipContent?.onClick) return;

    setShowTooltip(true);
    setIsTooltipClicked(true);
  };

  return (
    <OverlayTrigger
      placement={tooltipContent?.placement}
      show={showTooltip}
      overlay={tooltip}
      trigger={["hover", "focus", "click"]}
      onToggle={(isMouseEnter) => overlayHandler(isMouseEnter)}
    >
      <div
        onClick={onClickHandler}
        role="button"
        className="circle d-flex justify-content-center align-items-center"
        style={{ backgroundColor: circleColor, height: circleSize, width: circleSize }}
      >
        <img src={icon} />
      </div>
    </OverlayTrigger>
  );
};

CircleButton.propTypes = {
  // Icon that will be in center of circle
  icon: PropTypes.string.isRequired,
  // The color of the cirlce button.
  circleColor: PropTypes.string.isRequired,
  // Content data for ToolTip to render corrently
  tooltipContent: PropTypes.shape({
    id: PropTypes.string.isRequired,
    onHover: PropTypes.string.isRequired,
    onClick: PropTypes.string,
    placement: PropTypes.string,
    defaultShow: PropTypes.bool,
  }),
  // Dimensions of the circle
  circleSize: PropTypes.string,
};
