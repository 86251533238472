import { useAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import EmbedController from "../../../../embed/EmbedController";
import AlertSlackOfError from "../../../../monitoring/AlertSlackOfError";
import { useComponentsIndex } from "../../../hooks/useComponentsIndex";
import { getFinalPrice } from "../../../modules/getFinalPrice";
import { getShoppingCartData } from "../../../modules/getShoppingCartData";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { uploadImageToDigitalOcean } from "../../../modules/uploadImageToDigitalOcean";
import { canvas_base64, session_id } from "../../dataManagers/GlobalDataManagers";
import { Boop } from "../Animations/Boop";
import { CardElementCart } from "../CardElement/CardElementCart";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import "./styles.scss";

export const ShoppingCart = ({ productsState, componentsStateObj, itemsStateObj, previousComponentsState }) => {
  const componentsIndexObj = useComponentsIndex(componentsStateObj.state);
  const { deskModelIndex } = useComponentsIndex(componentsStateObj.state);
  const { handleSubmit } = useForm();
  const [ttCartData, shopifyIds, shopifyBuilderSummary] = useMemo(
    () => getShoppingCartData(componentsStateObj.state, itemsStateObj.state, componentsIndexObj),
    [itemsStateObj.state]
  );
  const [isCartSubmissionPending, setIsCartSubmissionPending] = useState(false);
  const finalPrice = getFinalPrice(productsState, ttCartData);
  const [sessionId] = useAtom(session_id);

  const handleBackClick = () => {
    componentsStateObj.setActiveId(previousComponentsState.includes("cart") ? componentsStateObj.state.array[deskModelIndex]._id : previousComponentsState);
  };

  // console.log({...shopifyBuilderSummary})

  // setup callback for response when parent cart is updated
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      EmbedController.setCartResponseCallback((errorMsg, redirectUrl) => {
        if (errorMsg || !redirectUrl) {
          console.log(`ERROR adding to shopping cart`);
          setIsCartSubmissionPending(false);
          AlertSlackOfError("ShoppingCart.jsx cart response", `error: ${errorMsg}`, sessionId);
          alert("Error submitting to cart. Please try again.");
        } else if (redirectUrl) {
          console.log(`cart redirectUrl in shopping cart: ${redirectUrl}`);
          setIsCartSubmissionPending(false);
          sendTransactionAnalytics();
          // NOTE: cart redirect was turned off since Mojo shopify changed to a slide out cart
          // EmbedController.sendParentUrlChange(redirectUrl);
        }
      });
    }
  }, []);

  const handleCheckout = () => {
    console.log("transaction fired", ...shopifyIds);

    // displays an indicator to shopper that submission to shopping cart has begun
    setIsCartSubmissionPending(true);

    // SendMessageToSlack(
    //   `
    //   🎉 Product added to cart 🎉
    //   Desk: ${itemsStateObj.state?.activeIds?.desk_model?._id} | ${itemsStateObj.state?.activeIds?.materials?.desk_material?._id}
    //   Price: ${finalPrice}
    //   Customized URL: <${EmbedController?.parentUrl || window.location.href}|here >
    //   Session Id: ${sessionId}
    //   isMobile: ${isMobile}
    //   `
    // )

    // signal to Scene.js to take a screenshot of caliper covers for the cart submission
    setProductScreenshotBase64(null);
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("ScreenshotCanvasForCartImage"));
    }, 500);
  };

  const [productScreenshotBase64, setProductScreenshotBase64] = useAtom(canvas_base64);

  useEffect(() => {
    if (productScreenshotBase64) submitToShoppingCart();
  }, [productScreenshotBase64]);

  async function submitToShoppingCart() {
    // update the custom image (screenshot of the caliper covers) to the cart obj so it's up-to-date
    let productScreenshotUrl = await uploadImageToDigitalOcean(productScreenshotBase64);
    shopifyBuilderSummary._preview = productScreenshotUrl;
    // add configUrl to properties
    shopifyBuilderSummary.configUrl = EmbedController?.parentUrl || window.location.href;

    console.log("shopifyBuilderSummary in submitToShoppingCart()", shopifyBuilderSummary);

    // determine if we should use the dev or production shopify product
    const deskProductId = shopifyBuilderSummary.configUrl.includes("/dev-") ? 40897969455215 : 40890748174447;

    let cartFormData = {
      items: [
        ...shopifyIds,
        {
          id: deskProductId,
          quantity: 1,
          properties: { ...shopifyBuilderSummary },
        },
      ],
    };

    // This rearranges the order of battle station shopping cart per client request
    const isBattleStationActive = itemsStateObj.state.activeIds.desk_model._id.includes("battle_station");
    if (isBattleStationActive) {
      const battleStationIds = [41170654953583, 41170654920815, 41170654888047];
      let targetItem;
      for (let i = 0; i < cartFormData.items.length; i++) {
        if (battleStationIds.includes(cartFormData.items[i]?.id)) {
          targetItem = cartFormData.items[i];
          cartFormData.items.splice(i, 1); // remove the item from the current position
          break;
        }
      }

      if (targetItem) {
        cartFormData.items.push(targetItem);
      }
    }

    console.log("cartFormData: ", cartFormData);

    EmbedController.sendAddToCart("/cart/add.js", cartFormData);
  }

  function sendTransactionAnalytics() {
    pushGTMAnalyticsEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: Math.floor(Math.random() * 10000), // Required - random unique id
            affiliation: "MojoDesk Configurator",
            revenue: finalPrice,
            tax: 0,
            shipping: 0,
          },
          products: ttCartData,
        },
      },
    });
  }

  return (
    <div className="ShoppingCart my-3">
      <div className="d-flex align-items-center justify-content-around mb-1">
        <Boop boopType="scale" scale="1.1" timing={200}>
          <ImgWithFallback
            src="/images/nav_btn_flags/back-btn-flag.webp"
            fallback="/images/nav_btn_flags/back-btn-flag.png"
            className="backButton"
            onClick={handleBackClick}
            onTouchStart={(e) => {
              e.preventDefault();
              handleBackClick();
            }}
          />
        </Boop>
        <h4 className="mb-0 font-weight-bold">Purchase Summary</h4>
        <div style={{ width: "30px" }}></div>
      </div>
      <div className="d-flex mt-4 mb-3">
        <h5>{`Total: $${getFinalPrice(productsState, ttCartData)}`}</h5>
        <form onSubmit={handleSubmit(handleCheckout)} style={{ marginLeft: "auto" }}>
          <button type="submit" disabled={isCartSubmissionPending || !EmbedController.isEmbedded} className="ms-auto checkoutBtn">
            Add To Cart
            {isCartSubmissionPending && <Spinner animation="border" style={{ width: "inherit", height: "inherit", verticalAlign: "0", marginLeft: "5px" }} />}
          </button>
        </form>
      </div>

      <CardElementCart cardContentArray={ttCartData} imgFrame={false} />
    </div>
  );
};
