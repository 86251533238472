import { useState } from "react";
import { useSpring, animated } from "react-spring";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const Accordion = ({ _id, displayName, defaultOpen, children }) => {
  const [open, setOpen] = useState(defaultOpen);

  //toggle accordion function
  let toggleHandler = () => {
    setOpen(!open);

    if (!open)
      pushGTMAnalyticsEvent({
        event: "Accordion Opened - MojoDesk",
        accordionTitle: _id,
        page: "accessories",
      });
  };

  const openAnimation = useSpring({
    from: { opacity: "0" },
    to: { opacity: "1" },
    config: { duration: "300" },
  });

  //rotate animation
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
      color: "#ffff",
    },
    to: {
      transform: open ? "rotate(180deg)" : "rotate(0deg)",
      color: open ? "#10d6f5" : "#fff",
    },
    config: { duration: "120" },
  });

  return (
    <>
      <animated.div className="accordion_item py-3 my-2" style={openAnimation} onClick={toggleHandler}>
        <div className="accordion_header d-flex align-items-center justify-content-between">
          <h5 className="mb-0">{displayName}</h5>
          <animated.i style={iconAnimation}>
            <img src="/images/iconTriangleOrange.svg" alt="expand icon" />
          </animated.i>
        </div>
      </animated.div>
      {open && children}
    </>
  );
};
