import { Boop } from "../Animations/Boop";
import { CircleButton } from "../CircleButton/CircleButton";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const WidgetMeasurements = ({ sizeOverlayStateObj }) => {
  // content to show in overlay on btn click
  const tooltipContent = {
    id: "measurements-tooltip",
    onHover: "Measurements",
    placement: "bottom",
    defaultShow: true,
  };

  const handleWidgetClick = () => {
    sizeOverlayStateObj.setState(!sizeOverlayStateObj.state);

    if (!sizeOverlayStateObj.state) pushGTMAnalyticsEvent({ event: "Toggle Size Info - MojoDesk" });
  };

  return (
    <Boop boopType="scale" scale={1.04} timing={200}>
      <div className="toggleSizeButton mx-1" onClick={handleWidgetClick}>
        <CircleButton circleColor="#FFFFFF" icon={"/images/iconRuler.png"} circleSize="40px" tooltipContent={tooltipContent} />
      </div>
    </Boop>
  );
};
