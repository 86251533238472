import axios from "axios";
import { atom, useAtom, useSetAtom } from "jotai";
import cloneDeep from "lodash.clonedeep";
import { traverse } from "object-traversal";
import { useCallback, useEffect, useState } from "react";
import sift from "sift";
import * as THREE from "three";

/**
 *
 * products
 *
 *
 */

// THIS IS THE GLOBAL products DATA OBJECT
export const products_state = atom({
  activeId: null,
  activeObj: null,
  array: null,
  isPrimed: false,
});

export const default_product_id = "custom_desk";

// function to update products_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_products_activeId = atom(null);

// holds the fetched product array
const all_products_array = atom(null);

/**
 *
 * components
 *
 */

// THIS IS THE GLOBAL components DATA OBJECT
export const components_state = atom({
  activeId: null,
  activeObj: null,
  array: null,
  isPrimed: false,
});

export const default_component_id = "desk_model";

// function to update components_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_components_activeId = atom(null);

// holds the fetched components array
const all_components_array = atom(null);

// *
// sections are referenced in components objects
// *
export const all_sections_array = atom(null);
export const all_parent_sections_array = atom(null)

/**
 *
 * items
 *
 */

// THIS IS THE GLOBAL items DATA OBJECT
export const items_state = atom({
  activeIds: null,
  activeObjs: null,
  array: null,
  isPrimed: false,
});

export const items_state_history = atom([]);

// function to update items_state (to be used around the app)
// when this gets updated, GlobalDataManagers will inform the URL to update accordingly
export const update_items_activeIds = atom(null);

// holds the fetched items array
const all_items_array = atom(null);

// holds the fetched items_list arrays
const items_list_arrays = atom(null);

// holds the fetched non_items array
const non_items_array = atom(null);

/**
 *
 *
 * MISC
 *
 *
 */

export const is_experience_loaded_and_revealed = atom(false);

// Used when we want loading screen for assets not loading by LoadingManager
export const loading_state = atom(false);
export const loading_count = atom(0);
export const update_loading_count = atom(null, (get, set, _arg) => set(loading_count, get(loading_count) + _arg));

// used to know when the caster_wheels are injected into the scene
export const caster_loaded_state = atom(null);

// manipulates desk's height animation
// "still", "up", or "down" are valid states
export const desk_animation_state = atom(null);

// holds the current desk height to display in the UI
export const desk_height_state = atom(null);

// maniuplates dome height animation
// "up", or "down" are valid states
export const dome_animation_state = atom(null);

// holds the current desk height to display in the UI
// bool
export const size_overlay_state = atom(null);

export const lookAtTarget_state = atom(new THREE.Vector3(0, 1, 0));

// holds list of mesh that should be outlined via post-processing
// array
export const outlined_mesh_array = atom([]);
export const push_to_outlined_mesh_array = atom(null, (get, set, _arg) => set(outlined_mesh_array, (prev) => [...prev, _arg]));

// used to store a base64 image of the canvas to send to shopping cart
export const canvas_base64 = atom(null);

// create Session Id for this runtime instance that can be used for us to identify errors
const { v4: uuidv4 } = require("uuid");
export const session_id = atom(uuidv4());

// data passed in from the UrlDataController that determines activeId's
export function GlobalDataManagers({
  // products
  products_activeId_fromURL,
  update_products_activeId_inURL,
  // components
  components_activeId_fromURL,
  update_components_activeId_inURL,
  // items
  items_activeIds_fromURL,
  update_items_activeIds_inURL,
}) {
  /**
   *
   * Load the data required to initialize the experience
   *
   */

  const [isInitDataLoaded, setIsInitDataLoaded] = useState(false);
  useEffect(() => {
    loadInitData();
  }, []);
  async function loadInitData() {
    await Promise.all([
      loadProductData(),
      loadComponentsData(),
      loadSectionsData(),
      loadParentSectionsData(),
      loadItemsData(),
      loadNonItemsData(),
      // loadItemsListData()
    ]);
    setIsInitDataLoaded(true);
  }

  /**
   *
   * products
   *
   *
   */

  const [productsState, setProductsState] = useAtom(products_state);
  const [requested_products_activeId, reset_update_products_activeId] = useAtom(update_products_activeId);
  const [allProductsArray, setAllProductsArray] = useAtom(all_products_array);

  // load products for experience to start
  async function loadProductData() {
    let res = await axios("/data/products.json");
    let allProducts = res.data;
    setAllProductsArray(allProducts);
  }

  // when some child tells us to update products_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_products_activeId) {
      update_products_activeId_inURL(requested_products_activeId);
      reset_update_products_activeId(null); // reset this so it'll always register as an update
    }
  }, [requested_products_activeId]);

  // when URL tells us there is a new active product, update products_state
  useEffect(() => {
    if (!isInitDataLoaded || !products_activeId_fromURL) return;
    let productsArray = filterProductData();
    let activeProduct = productsArray.find((productObj) => {
      return productObj._id === products_activeId_fromURL;
    });
    let newProductsState = {
      activeId: products_activeId_fromURL,
      activeObj: activeProduct,
      array: productsArray,
      isPrimed: true,
    };
    setProductsState(newProductsState);
    console.log(`____ NEW productsState ______`, newProductsState);
  }, [products_activeId_fromURL, isInitDataLoaded]);

  // filter and return an array with the active product
  function filterProductData() {
    let applicableProducts = allProductsArray.filter(sift({ _id: { $in: [products_activeId_fromURL] } }));
    return applicableProducts;
  }

  /**
   *
   * components
   *    also, sections are nested inside components
   *    also, items/items_list are nested inside components
   *
   */

  const [componentsState, setComponentsState] = useAtom(components_state);
  const [requested_components_activeId, reset_update_components_activeId] = useAtom(update_components_activeId);
  const [allComponentsArray, setAllComponentsArray] = useAtom(all_components_array);
  const [allSectionsArray, setAllSectionsArray] = useAtom(all_sections_array);
  const [allParentSectionsArray, setAllParentSectionsArray] = useAtom(all_parent_sections_array)
  const [itemsListArrays, setItemsListArrays] = useAtom(items_list_arrays);

  // load components for experience to start
  async function loadComponentsData() {
    let res = await axios("/data/components.json");
    let allComponents = res.data;
    setAllComponentsArray(allComponents);
  }
  async function loadSectionsData() {
    let res = await axios("/data/sections.json");
    let allSections = res.data;
    setAllSectionsArray(allSections);
  }
  async function loadParentSectionsData() {
    let res = await axios("/data/desk-sections.json");
    let allSections = res.data;
    setAllParentSectionsArray(allSections);
  }

  // when some child tells us to update components_state.activeId, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_components_activeId) {
      update_components_activeId_inURL(requested_components_activeId);
      reset_update_components_activeId(null); // reset this so it'll always register as an update
    }
  }, [requested_components_activeId]);

  // when active product changes, we tell the URL to update the active component to be the product's first component
  // unless active component is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!productsState.activeId || components_activeId_fromURL) return;
    let activeProduct = productsState.array.find((productObj) => productObj._id === productsState.activeId);
    update_components_activeId_inURL(activeProduct.components[0]);
  }, [productsState.activeId]);

  // when URL tells us there is a new active component, update components_state
  useEffect(() => {
    if (!components_activeId_fromURL || !productsState.isPrimed) return;
    let componentChoicesArray = filterComponentData();
    let activeComponent = componentChoicesArray.find((componentObj) => componentObj._id === components_activeId_fromURL);
    let newComponentsState = {
      activeId: components_activeId_fromURL,
      activeObj: activeComponent,
      array: componentChoicesArray,
      isPrimed: true,
    };
    setComponentsState(newComponentsState);
    console.log(`____ NEW componentsState ______`, newComponentsState);
  }, [components_activeId_fromURL, productsState.isPrimed]);

  // filter and return an array with the list of components
  function filterComponentData() {
    let applicableComponentIds = productsState.array.find((productObj) => productObj._id === productsState.activeId).components;
    let applicableComponents = allComponentsArray.filter(sift({ _id: { $in: applicableComponentIds } }));
    // make applicableComponents have same order as product.components array
    applicableComponents.sort((a, b) => {
      return applicableComponentIds?.indexOf(a._id) - applicableComponentIds?.indexOf(b._id);
    });
    // no need to update sections and items again
    if (componentsState.isPrimed) return applicableComponents;
    // inject correct sections objects and items_list id's
    applicableComponents.forEach((componentObj) => {
      // sections
      let sectionIds = componentObj.sections;
      sectionIds?.forEach((sectionId, index) => {
        componentObj.sections[index] = allSectionsArray.find((sectionObj) => sectionObj._id === sectionId);
      });
      // Parent Sections
      let parentSectionIds = componentObj.ParentSections;
      parentSectionIds?.forEach((sectionId, index) => {
        componentObj.ParentSections[index] = allParentSectionsArray.find((sectionObj) => sectionObj._id === sectionId);
      });
      if (componentObj.ParentSections) {
        componentObj.ParentSections.forEach((item, index) => {
          return item.children?.forEach((i, indx) => {
            componentObj.ParentSections[index].children[indx] = allSectionsArray.find((sectionObj) => sectionObj._id === i);
          })
        })
      }
      // items_list
      let expandedItemsArray = [];
      componentObj.items.forEach((itemId, index) => {
        // expand any _items_list
        if (itemId.includes("_items_list")) {
          let itemsArray_fromList = itemsListArrays[itemId];
          expandedItemsArray = expandedItemsArray.concat(itemsArray_fromList);
        } else {
          expandedItemsArray.push(itemId);
        }
      });
      // update component's .items
      componentObj.items = expandedItemsArray;
    });
    return applicableComponents;
  }

  /**
   *
   * items / non_items
   *
   */

  const [itemsState, setItemsState] = useAtom(items_state);
  const setItemsActiveIds = useSetAtom(update_items_activeIds);
  const [itemsStateHistory, setItemsStateHistory] = useAtom(items_state_history);
  const [requested_items_activeIds, reset_update_items_activeId] = useAtom(update_items_activeIds);
  const [allItemsArray, setAllItemsArray] = useAtom(all_items_array);
  const [allNonItemsArray, setAllNonItemsArray] = useAtom(non_items_array);

  function defaultItemActiveIds() {
    let activeIds = {
      desk_model: { _id: "cubicle_rectangle_60" },
      materials: {
        desk_material: { _id: "american_oak" },
        base_material: { _id: "black_base" },
      },
      accessories: {
        array: [],
      },
      non_items: {
        environment_state: {
          _id: "executive_office",
        },
      },
    };
    return activeIds;
  }

  // load items for experience to start
  async function loadItemsData() {
    let res = await axios("/data/items.json");
    let allItems = res.data;
    allItems = updatePricingFromShopify(allItems);
    setAllItemsArray(allItems);
  }
  async function loadNonItemsData() {
    let res = await axios("/data/non_items.json");
    let allNonItems = res.data;
    setAllNonItemsArray(allNonItems);
  }

  function updatePricingFromShopify(itemsArray) {
    if (!window._tt?.shopifyData_tt) return itemsArray;

    // set by shopify liquid code
    let shopifyData = window._tt.shopifyData_tt;

    let updatedAllItemsArray = [];

    itemsArray.forEach((item) => {
      let updatedItem = { ...item };

      // find price in shopifyData
      let price = shopifyData[item._id]?.price / 100 || null;

      if (price) {
        updatedItem.price = price;
      }

      // handle combo monitors
      else if (item._id === "one_dual_one_single_monitors") {
        updatedItem.price = (shopifyData["one_single_monitor"].price + shopifyData["one_dual_monitor"].price) / 100;
      } else if (
        item._id === "one_dual_two_single_monitors" ||
        item._id === "one_dual_two_single_monitors_corner" ||
        item._id === "one_dual_two_single_monitors_corner60"
      ) {
        updatedItem.price = (shopifyData["two_single_monitors"].price + shopifyData["one_dual_monitor"].price) / 100;
      }

      updatedAllItemsArray.push(updatedItem);
    });

    return updatedAllItemsArray;
  }

  // load items_list for experience to start
  // async function loadItemsListData() {
  //   let [color_items_list, engravings_default_items_list] = await Promise.all([
  //     loadColorItems(),
  //     loadEngravingItems(),
  //   ])
  //   setItemsListArrays({
  //     "color_items_list": color_items_list,
  //     "engravings_default_items_list": engravings_default_items_list,
  //   });
  // }
  // async function loadColorItems() {
  //   let res = await fetch('/data/items_list/color_items_list.json');
  //   let array = await res.json();
  //   return array;
  // }
  // async function loadEngravingItems() {
  //   let res = await fetch('/data/items_list/engravings_default_items_list.json');
  //   let array = await res.json();
  //   return array;
  // }

  // when some child tells us to update items_state.activeIds, we tell the URL to update accordingly
  useEffect(() => {
    if (requested_items_activeIds) {
      let mutated_requested_items_activeIds = handleDefaultItemsForDeskTypes(requested_items_activeIds); // CUSTOM CODE
      update_items_activeIds_inURL(mutated_requested_items_activeIds);
      reset_update_items_activeId(null); // reset this so it'll always register as an update
    }
  }, [requested_items_activeIds]);

  const updateEnvironment = useCallback(
    (id) => {
      if (!id.includes("battle_station") && itemsState.activeIds.non_items.environment_state._id !== "executive_office") {
        let copy = { ...itemsState.activeIds };
        copy.non_items.environment_state = { _id: "executive_office" };
        setItemsActiveIds(copy);
        setItemsStateHistory("executive_office");
      }
      if (id.includes("battle_station") && itemsState.activeIds.non_items.environment_state._id !== "game_room") {
        let copy = { ...itemsState.activeIds };
        copy.non_items.environment_state = { _id: "game_room" };
        setItemsStateHistory("game_room");
        setItemsActiveIds(copy);
      }
    },
    [itemsState.activeIds, setItemsActiveIds]
  );

  useEffect(() => {
    if (itemsStateHistory[1] !== itemsState?.activeIds?.desk_model?._id) {
      updateEnvironment(itemsState?.activeIds?.desk_model?._id);
    }
  }, [itemsState, itemsStateHistory, updateEnvironment]);

  function handleIncludedItems(items_activeIds, itemsArray) {
    itemsArray.forEach((item) => {
      if (item.metaData?.isIncluded && item.section === "desk_accessories" && !items_activeIds["accessories"].array.find((i) => i._id === item._id)) {
        items_activeIds["accessories"].array.push({ _id: item._id });
      }
    });
    return items_activeIds;
  }

  // CUSTOM CODE: handles default base materials for different desk types
  function handleDefaultItemsForDeskTypes(requested_items_activeIds) {
    let mutated_requested_items_activeIds = { ...requested_items_activeIds };
    // if desk_model is a 3leg corner desk, set base_material to black_base_3legs
    if (mutated_requested_items_activeIds.desk_model._id.slice(0, 6) === "corner") {
      mutated_requested_items_activeIds.materials.base_material = {
        _id: "black_base_3legs",
      };
      // now make sure the material_editing_status is the desk_material and not base_material, since there's only 1 default base choice
      let material_component = componentsState.array.find((component) => component._id === "materials");
      material_component.material_editing_status = "desk_material";
    }
    // if desk_model is a 2leg desk, set base_material to black_base
    else if (mutated_requested_items_activeIds.materials.base_material._id === "black_base_3legs") {
      mutated_requested_items_activeIds.materials.base_material = {
        _id: "black_base",
      };
    }
    // mojodome should use white top and black base as default colors
    if (mutated_requested_items_activeIds.desk_model._id.includes("dome") && !itemsState.activeObjs.desk_model._id.includes("dome")) {
      mutated_requested_items_activeIds.materials.desk_material = {
        _id: "classic_solid_white",
      };
      mutated_requested_items_activeIds.materials.base_material = {
        _id: "black_base",
      };
    }

    // Default properties for battle station
    else if (mutated_requested_items_activeIds.desk_model._id.includes("battle_station")) {
      // battle station should use white top as default color
      mutated_requested_items_activeIds.materials.desk_material = {
        _id: "carbon_fiber",
      };
      // battle station should use black base as default color
      mutated_requested_items_activeIds.materials.base_material = {
        _id: "black_base",
      };
      // battle station monitor arms
      if (mutated_requested_items_activeIds.desk_model._id === "battle_station_single") {
        mutated_requested_items_activeIds.accessories.array = [
          {
            _id: "one_single_monitor_battlestation_bundle",
          },
        ];
      }
      if (mutated_requested_items_activeIds.desk_model._id === "battle_station_dual") {
        mutated_requested_items_activeIds.accessories.array = [
          {
            _id: "one_dual_monitor_battlestation_bundle",
          },
        ];
      }
      if (mutated_requested_items_activeIds.desk_model._id === "battle_station_triple") {
        mutated_requested_items_activeIds.accessories.array = [
          {
            _id: "three_single_monitors_battlestation_bundle",
          },
        ];
      }
    }

    // other desks should use american oak as default color
    else if (
      !mutated_requested_items_activeIds.desk_model._id.includes("dome") &&
      mutated_requested_items_activeIds.desk_model._id != itemsState.activeObjs.desk_model._id
    ) {
      mutated_requested_items_activeIds.materials.desk_material = {
        _id: "american_oak",
      };
    }
    return mutated_requested_items_activeIds;
  }

  // when active product changes & components_state is primed, we tell the URL to update the activeIds to each component's default item
  // happens on site load unless activeIds is already set in URL (i.e. shopper returning to a saved config)
  useEffect(() => {
    if (!componentsState.isPrimed || items_activeIds_fromURL) return;
    update_items_activeIds_inURL(defaultItemActiveIds());
  }, [productsState.activeId, componentsState.isPrimed]);

  // when URL tells us there is a new items.activeIds, update items_state
  useEffect(() => {
    if (!items_activeIds_fromURL || !componentsState.isPrimed) return;
    let items_activeIds_clone = cloneDeep(items_activeIds_fromURL);
    let newItemsArray = getUpdatedItemsArray(items_activeIds_clone);
    let mutated_items_activeIds = handleIncludedItems(items_activeIds_clone, newItemsArray);
    let newActiveObjs = getActiveItemObjs(mutated_items_activeIds, newItemsArray);

    let newItemsState = {
      activeIds: mutated_items_activeIds,
      activeObjs: newActiveObjs,
      array: newItemsArray,
      isPrimed: true,
    };
    setItemsState(newItemsState);
    setItemsStateHistory((history) => {
      return [newItemsState.activeIds.desk_model._id, ...history].slice(0, 2);
    });
    console.log(`____ NEW itemsState ______`, newItemsState);
  }, [items_activeIds_fromURL, componentsState.isPrimed]);

  function getActiveItemObjs(activeIds, itemsArray) {
    let activeObjs = cloneDeep(activeIds);
    let mods = {};

    const updateObjViaPaths = (objToUpdate, mods) => {
      for (var path in mods) {
        var k = objToUpdate;
        var steps = path.split(".");
        steps.pop(); // removing the _id entry so we replace whole object
        var last = steps.pop();
        steps.forEach((e) => (k[e] = k[e] || {}) && (k = k[e]));
        k[last] = mods[path];
      }
      return objToUpdate;
    };

    traverse(activeObjs, (context) => {
      let { parent, key, value, meta } = context;
      if (key === "_id") {
        // we've found an abbreviated item obj
        // add its path and the full item obj to mods
        mods[meta.currentPath] = {
          ...itemsArray.find((item) => item._id === value),
        };
      }
    });

    activeObjs = updateObjViaPaths(activeObjs, mods);
    return activeObjs;
  }

  // traverses activeIds obj and finds any inputs
  // uses those inputs to update item in itemsArray
  // also applies updates from dependencies
  function getUpdatedItemsArray(activeIds) {
    let itemsArrayCopy;
    // prime the itemsState.array if not already present
    if (!itemsState.array) itemsArrayCopy = getApplicableItemsArray();
    // use copy of itemsState.array so any existing inputs or edits are copied
    else itemsArrayCopy = itemsState.array;

    // makes array of activeId's for use in updateItemFromDependencies
    let activeIdArray = [];
    traverse(activeIds, (context) => {
      const { parent, key, value } = context;
      if (key === "_id") activeIdArray.push(value);
      // update items from inputs
      if (key === "inputs" && value) {
        let item = itemsArrayCopy.find((obj) => obj._id === parent._id);
        updateItemWithInputs(item, value);
      }
    });

    // update items from dependencies
    itemsArrayCopy.forEach((item) => {
      updateItemFromDependencies(item, activeIdArray);
    });

    return itemsArrayCopy;
  }

  function getApplicableItemsArray() {
    // make array of all applicable item id's
    let itemIdArray = [];
    componentsState.array.forEach((component) => {
      itemIdArray = itemIdArray.concat(component.items);
    });
    // filter the allItemsArray to only include the applicable ones according to itemIdArray
    let filteredItemsArray = allItemsArray.filter(sift({ _id: { $in: itemIdArray } }));

    // if there are non_items, add those to the items array
    if (allNonItemsArray) filteredItemsArray = filteredItemsArray.concat(allNonItemsArray);

    return filteredItemsArray;
  }

  // updates the item specified with the new inputs
  function updateItemWithInputs(item, newInputObj) {
    // pasting the newInputObj to item.inputs
    item.inputs = newInputObj;
    // traverse new inputs
    Object.entries(newInputObj).forEach(([inputKey, inputValue]) => {
      // traverse item
      traverse(item, (context) => {
        const { parent, key, value, meta } = context;
        // update item values with input values
        if (inputKey == key && !meta.currentPath.includes("inputs") && inputValue !== null) {
          parent[key] = inputValue;
        }
      });
    });
  }

  function updateItemFromDependencies(item, activeIdArray) {
    if (item.dependencies && item.dependencies.length > 0) {
      // reset defaults, if applicable
      if (item.excluded) item.excluded = false;
      if (item.metaData?.isIncluded) item.metaData = { isIncluded: false };
      // iterate the dependency objects
      item.dependencies.forEach((dependencyObj) => {
        // check if an active item._id is in this dependency object's .itemValues
        dependencyObj.itemValues.forEach((itemId) => {
          if (activeIdArray.includes(itemId)) {
            // apply the dependency updates to the item
            Object.entries(dependencyObj.updates).forEach(([keyToUpdate, newValue]) => {
              item[keyToUpdate] = newValue;
            });
          }
        });
      });
    }
  }

  return null;
}
