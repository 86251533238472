import { useMemo, useState } from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import sift from "sift";
import { useSpring, animated } from "react-spring";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const DropDown = ({ itemsStateObj }) => {
  const dropDownItemsArray = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ section: { $eq: "environment_state" } }));
  }, []);
  const activeItem = itemsStateObj.state.activeObjs.non_items.environment_state;
  const [isToggled, setToggle] = useState(false);
  const { y } = useSpring({ y: isToggled ? 180 : 0 });
  const activeItemStyles = useSpring(isToggled ? { background: "#ff6600", color: "white" } : { background: "white", color: "black" });
  const dropDownAppeared = useSpring({
    transform: isToggled ? "translate3D(0,0,0)" : "translate3D(0,-40px,0)",
    opacity: isToggled ? 1 : 0,
  });

  const handleClick = (item) => {
    let copy = { ...itemsStateObj.state.activeIds };
    copy.non_items.environment_state = { _id: item._id };

    itemsStateObj.setState(copy);
    setToggle(!isToggled);

    pushGTMAnalyticsEvent({ event: "Room Changed - MojoDesk", activeRoom: item._id, device: "desktop" });
  };

  // dropdown items to display
  const displayItems = dropDownItemsArray.map((item, index) => {
    if (activeItem._id === item._id) return null;

    return (
      <div key={index} className="dropDownItem my-2 p-2" role="button" onClick={() => handleClick(item)}>
        {item.displayName}
      </div>
    );
  });

  return (
    <div className="DropDown">
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip style={{ zIndex: isToggled ? -1 : 1000 }} id="tooltip-details-dropDown">
            {" "}
            Change Room{" "}
          </Tooltip>
        }
      >
        <animated.button style={activeItemStyles} className="wrapper" onClick={() => setToggle(!isToggled)}>
          <div className="d-flex align-items-center my-auto">
            <h6 className="mb-0 me-2">{activeItem.displayName}</h6>
            <animated.i className="ms-auto mb-0" style={{ transform: y.to((y) => `rotateX(${y}deg)`) }}>
              <img src="/images/iconTriangleBlack.svg" alt="expand icon" />
            </animated.i>
          </div>
        </animated.button>
      </OverlayTrigger>
      <animated.div style={dropDownAppeared} className="dropDownContent mt-2">
        {isToggled && displayItems}
      </animated.div>
    </div>
  );
};
