import * as THREE from "three";
import React, { Suspense, useState, useMemo, useEffect, useRef } from "react";
import { Canvas, useFrame, useThree, useLoader, extend } from "@react-three/fiber";
import { OrbitControls, useAnimations, PresentationControls } from "@react-three/drei";
// import { traverse } from 'object-traversal';
// import sift from 'sift';
import { ResizeObserver } from "@juggle/resize-observer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EnvironmentController from "./EnvironmentController";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { KTX2Loader } from "../../../scripts/PatchedKTX2Loader.js";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { GammaCorrectionShader } from "three/examples/jsm/shaders/GammaCorrectionShader";
import AssetSystem3d, { useAssetLoader } from "../dataManagers/AssetSystem3d";
import { useActiveItem } from "../../modules/useActiveItem";
import { isMobile } from "react-device-detect";

import { atom, useAtom } from "jotai";
import {
  products_state,
  items_state,
  // components_state,
  // loading_state,
  caster_loaded_state,
  update_loading_count,
  lookAtTarget_state,
  size_overlay_state,
  desk_animation_state,
  dome_animation_state,
  outlined_mesh_array,
  push_to_outlined_mesh_array,
  is_experience_loaded_and_revealed,
  desk_height_state,
  canvas_base64,
  // update_items_activeIds
} from "../dataManagers/GlobalDataManagers";
// import { set } from 'lodash';
// import PanCameraFromCursorControls from './PanCameraFromCursorControls';

extend({ EffectComposer, RenderPass, OutlinePass, ShaderPass });

var TWEEN = require("tween.js");

export default function Scene() {
  const [productsState] = useAtom(products_state);
  const [itemsState] = useAtom(items_state);
  const [lookAtTarget] = useAtom(lookAtTarget_state);

  return (
    <>
      {/* canvas that will hold a screenshot of the main three.js canvas for the shopping cart */}
      <canvas id="screenshot_canvas" style={{ display: "none" }} width={1024} height={512}></canvas>

      {/* three scene's canvas */}
      <Canvas
        id="builder-scene-canvas-container"
        className="shared-scene-sizing builder-scene-canvas-container"
        camera={{
          position: [0, 1.8, 3],
          rotation: [0, 0, 0],
          fov: isMobile ? 40 : 40,
          near: 0.1,
          far: 20,
        }}
        gl={{ physicallyCorrectLights: true }}
        flat={true} // sets renderer.toneMapping = THREE.NoToneMapping
        dpr={[1, 2.5]} // handles resolution of canvas
        shadows={{ enabled: true, type: THREE.PCFShadowMap }}
        resize={{ polyfill: ResizeObserver }}
      >
        {/* combo of vertical orbit controls with zoom & presentation controls to enable springy rotation of env around the Y axis */}
        <OrbitControls
          makeDefault
          autoRotate={false}
          enableKeys={false}
          enablePan={false}
          enableRotate={true}
          enableZoom={true}
          minDistance={1.4}
          maxDistance={4}
          target={lookAtTarget}
          enableDamping={true}
          rotateSpeed={isMobile ? 0.7 : 0.35}
          minAzimuthAngle={0} // horizontal
          maxAzimuthAngle={0} // horizontal
          minPolarAngle={0} // vertical (TOP)
          maxPolarAngle={2.7} // vertical (BOTTOM)
        />
        <PresentationControls
          global={true} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on dragx
          snap={true} // Snap-back to center (can also be a spring config)
          speed={isMobile ? 2 : 1.5} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[0, 0, 0]} // Default rotation
          polar={[0, 0]} // Vertical limits
          config={{ mass: 1, tension: 150, friction: 50 }} // Spring config
        >
          <group name="PresentationControlsChild">
            <AssetSystem3d>
              <Suspense fallback={null}>
                {/* environment assets, lighting, etc.  */}
                {itemsState.isPrimed && ( // NOTE: custom code for this experience since the env relies on itemsState
                  <EnvironmentController />
                )}

                {/* Root of configuration experience */}
                {productsState.isPrimed && itemsState.isPrimed && <ExperienceManager productsState={productsState} itemsState={itemsState} />}
              </Suspense>
            </AssetSystem3d>
          </group>
        </PresentationControls>
      </Canvas>
    </>
  );
}

function ExperienceManager({ productsState, itemsState }) {
  // whenever scene is revealed,
  useEffect(() => {
    document.addEventListener("SceneIsBeingRevealed", handleSceneReveal);
    return () => {
      document.removeEventListener("SceneIsBeingRevealed", handleSceneReveal);
    };
  }, []);
  function handleSceneReveal() { }

  /**
   *
   * Convert the scene's canvas to a base64 image that can be sent to the client's shopping cart
   *
   */

  useEffect(() => {
    document.addEventListener("ScreenshotCanvasForCartImage", createProductScreenshot);
    return () => document.removeEventListener("ScreenshotCanvasForCartImage", createProductScreenshot);
  }, []);

  const [, setCanvasBase64] = useAtom(canvas_base64);
  const [deskHeightState] = useAtom(desk_height_state);
  const deskHeightState_ref = useRef(deskHeightState);
  useEffect(() => {
    deskHeightState_ref.current = deskHeightState;
  }, [deskHeightState]);

  const { gl, scene } = useThree();
  const ourCamera_ref = React.useRef(new THREE.PerspectiveCamera(40, 2, 0.1, 10));

  function createProductScreenshot() {
    // using a different camera that we can control the view without the shopper noticing
    let cameraY = 1.3 + ((deskHeightState_ref.current - 24) / (49 - 24)) * 0.55;
    ourCamera_ref.current.position.set(0, cameraY, 2.2);
    ourCamera_ref.current.rotation.set(-0.4, 0, 0);

    // setting the size of the render canvas so it matches the size of the screenshot canvas.
    // 3rd param 'false' makes the canvas not change css sizes so shopper doesn't notice
    gl.setSize(1024, 512, false);

    // render the scene with our camera
    gl.render(scene, ourCamera_ref.current);

    // draw image centered on canvas with correct aspect
    let sceneCanvas = gl.domElement;
    let screenshotCanvas = document.getElementById("screenshot_canvas");
    let ctx = screenshotCanvas.getContext("2d");
    var hRatio = screenshotCanvas.width / sceneCanvas.width;
    var vRatio = screenshotCanvas.height / sceneCanvas.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (screenshotCanvas.width - sceneCanvas.width * ratio) / 2;
    var centerShift_y = (screenshotCanvas.height - sceneCanvas.height * ratio) / 2;
    ctx.clearRect(0, 0, screenshotCanvas.width, screenshotCanvas.height);
    ctx.drawImage(
      sceneCanvas,
      0,
      0,
      sceneCanvas.width,
      sceneCanvas.height,
      centerShift_x,
      centerShift_y,
      sceneCanvas.width * ratio,
      sceneCanvas.height * ratio
    );

    // save as base64
    let base64 = screenshotCanvas.toDataURL("image/png");
    setCanvasBase64(base64);

    // reset the canvas size to the original size so resolution is correct
    let viewportEl = document.getElementById("builder-scene-canvas-container");
    gl.setSize(viewportEl.clientWidth, viewportEl.clientHeight, false);
  }

  /**
   *
   *
   * Custom Logic For This Experience
   *
   *
   */

  /**
   * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
   */

  return (
    <>
      <ModelController itemContainerId="desk_model" itemsState={itemsState} />
    </>
  );
}

function ModelController({ itemContainerId, itemsState }) {
  // need to know when experience is loaded and revealed
  const [isExperienceLoadedAndRevealed] = useAtom(is_experience_loaded_and_revealed);

  const [, updateLoadingState] = useAtom(update_loading_count);
  const getAsset = useAssetLoader();

  // fetch the active item that references the model we want
  const applicableItem = useActiveItem(itemContainerId);

  // load the model
  const modelContainer_ref = useRef();
  const [model, setModel] = useState();

  useEffect(() => {
    loadAndInjectModel();
  }, [applicableItem.modelSrc]);

  async function loadAndInjectModel() {
    setModel(null);
    modelContainer_ref.current.clear();
    updateLoadingState(1);
    let newModel = await getAsset(applicableItem.modelSrc);
    addShadowToAllMesh(newModel.scene);
    setModel(newModel);
  }

  useEffect(() => {
    if (!model?.scene) return;
    modelContainer_ref.current.add(model.scene);
    updateLoadingState(-1);
  }, [model]);

  function addShadowToAllMesh(scene) {
    scene.traverse((node) => {
      if (node.isMesh) {
        node.castShadow = true;
        node.receiveShadow = true;
      }
    });
  }

  /**
   *
   *
   * Custom Logic For This Experience
   *
   *
   */

  // the desk_model's base-bottom mesh needs to move up and down depending upon
  // if the caster_wheels accessory is active or not
  // ------------------------------------------------------------------------------

  // this tells us if the caster_wheels are active or not and updates whenever they are injected into the scene
  const [casterLoadedState, setCasterLoadingState] = useAtom(caster_loaded_state);

  // reset caster wheels state when new desk is chosen
  useEffect(() => {
    setCasterLoadingState(false);
    moveDeskPositionOnWheelsToggle();
  }, [model]);

  useEffect(() => {
    moveDeskPositionOnWheelsToggle();
  }, [casterLoadedState]);

  const casterWheelsNode = useMemo(() => {
    return model?.scene?.getObjectByName("caster_wheels");
  }, [model]);
  function moveDeskPositionOnWheelsToggle() {
    if (!casterWheelsNode) return;
    // check for caster_wheels being active
    if (casterLoadedState) deskUpTween.start();
    else deskDownTween.start();
  }

  // animating the position change of the desk for caster wheels
  let deskYPos_ref = useRef(0);
  const baseMesh = useMemo(() => {
    return model?.scene?.getObjectByName("base-bottom");
  }, [model]);

  var deskUpTween = useMemo(() => {
    let t = new TWEEN.Tween(deskYPos_ref).to({ current: 0.053 }, 100).onUpdate(() => {
      baseMesh.position.set(0, deskYPos_ref.current, 0);
    });
    return t;
  }, [baseMesh]);

  var deskDownTween = useMemo(() => {
    let t = new TWEEN.Tween(deskYPos_ref)
      .to({ current: 0 }, 100)
      .onUpdate(() => {
        baseMesh.position.set(0, deskYPos_ref.current, 0);
      })
      .onComplete(() => {
        baseMesh.position.set(0, deskYPos_ref.current, 0);
      });
    return t;
  }, [baseMesh]);

  /**
   * handle hiding and revealing the desk's wall shadow depending upon the active environment
   */

  const activeEnvId = useActiveItem("non_items").environment_state?._id;
  useEffect(() => {
    model?.scene?.traverse((node) => {
      if (node.isMesh && node.name.includes("shadowWall")) {
        node.visible = activeEnvId === "executive_office" ? false : true;
      }
    });
  }, [model, activeEnvId]);

  /**
   * handle the camera's zoom for the 3 leg corner desks
   */
  const { camera } = useThree();
  const threeLegDesks = useMemo(() => ["corner_72_both", "corner_60_both", "corner_72_left", "corner_72_right"], []);
  useEffect(() => {
    if (threeLegDesks?.includes(itemsState.activeIds?.desk_model?._id)) camera.position.set(0, 1.9, 4);
    else camera.position.set(0, 1.8, 3);
  }, [itemsState.activeIds.desk_model._id]);

  // ------------------------------------------------------------------------------

  const [outlinedMeshArray] = useAtom(outlined_mesh_array);

  // update TWEEN for tweens to be used in the experience
  useFrame(() => {
    TWEEN.update();
  });

  /**
   * ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
   */

  return (
    <>
      <group ref={modelContainer_ref}>{/* model gets injected here */}</group>

      {/* these all rely on model */}
      {model?.scene && (
        <>
          <AnimationController
            animationClips={model.animations}
            animationName="height-animation"
            meshToAnimate={model.scene.getObjectByName("base-middle-animated")}
          />
          <AnimationController
            animationClips={model.animations}
            animationName="height-animation"
            meshToAnimate={model.scene.getObjectByName("base-top-animated")}
          />
          {itemsState.activeIds?.desk_model?._id === "mojo_dome_60" && (
            <DomeAnimationController
              animationClips={model.animations}
              animationName="dome-animation"
              meshToAnimate={model.scene.getObjectByName("dome-mid-out")}
            />
          )}

          <MaterialController itemContainerId="desk_material" materialName="desk-material-production" productModelScene={model.scene} />
          <MaterialController itemContainerId="base_material" materialName="base-material-production" productModelScene={model.scene} />
          <MaterialController itemContainerId="desk_material" materialName="storage-drawers-variable" productModelScene={model.scene} />

          {isExperienceLoadedAndRevealed && <MultiAttachmentModelController itemContainerId="accessories" rootModelScene={model.scene} />}

          {/* handles outline effect via post-processing */}
          {/* { outlinedMeshArray && outlinedMeshArray.length > 0 && <OutlinePostProcessing outlinedMeshArray={outlinedMeshArray} /> } */}

          {/* handles the holographic size info that overlays the desk */}
          <SizeOverlayController rootScene={model.scene} />
        </>
      )}
    </>
  );
}

// TODO: implement change discussed here (https://github.com/pmndrs/react-three-fiber/discussions/2379) so textures can be disposed of
function OutlinePostProcessing({ outlinedMeshArray }) {
  const { gl, scene, camera, size } = useThree();
  const composer = useRef();
  const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size]);

  useEffect(() => composer.current.setSize(size.width, size.height), [size]);

  // console.log('gl', gl)
  // console.log('gl.info.memory.textures', gl.info.memory.textures)
  // useEffect(() => {
  //   return () => {
  //     console.log('composer.current', composer.current)
  //   }
  // }, [])

  useFrame(() => {
    composer.current.render();
  }, 1);

  return (
    <effectComposer ref={composer} args={[gl]}>
      <renderPass attachArray="passes" args={[scene, camera]} />
      {outlinedMeshArray.length > 0 && (
        <>
          <outlinePass
            attachArray="passes"
            args={[aspect, scene, camera]}
            selectedObjects={outlinedMeshArray}
            visibleEdgeColor="white"
            hiddenEdgeColor="white"
            edgeStrength={5}
            edgeThickness={0.3}
          />
          <shaderPass attachArray="passes" args={[FXAAShader]} uniforms-resolution-value={[1 / size.width, 1 / size.height]} />
          <shaderPass attachArray="passes" args={[GammaCorrectionShader]} />
        </>
      )}
    </effectComposer>
  );
}

function SizeOverlayController({ rootScene }) {
  const sizeOverlayMaterial = useMemo(() => {
    let mat;
    rootScene.traverse((node) => {
      if (node.isMesh && node.material.name.includes("size-info")) mat = node.material;
    });
    if (!mat) return null;
    mat.transparent = true;
    mat.opacity = 0.5;
    mat.visible = false; // start the material in hidden state
    return mat;
  }, [rootScene]);

  const sizeLabelMaterials = useMemo(() => {
    let matList = [];
    rootScene.traverse((node) => {
      if (node.isMesh && node.material.name.includes("size-label")) matList.push(node.material);
    });
    if (matList?.length == 0) return null;
    matList.forEach((mat) => {
      mat.transparent = true;
      mat.opacity = 0.75;
      mat.visible = false; // start the material in hidden state
    });
    return matList;
  }, [rootScene]);

  const [sizeOverlayState] = useAtom(size_overlay_state);
  useEffect(() => {
    if (sizeOverlayMaterial) sizeOverlayMaterial.visible = sizeOverlayState;
    if (sizeLabelMaterials) {
      sizeLabelMaterials.forEach((mat) => {
        mat.visible = sizeOverlayState;
      });
    }
  }, [sizeOverlayState, rootScene]);

  return null;
}

function DomeAnimationController({ animationClips, animationName, meshToAnimate }) {
  // setup Mixer
  const mixer = useMemo(() => {
    if (!meshToAnimate) return null;
    const animationGroup = new THREE.AnimationObjectGroup();
    meshToAnimate.traverse((node) => {
      if (node.isMesh) animationGroup.add(node);
    });
    return new THREE.AnimationMixer(animationGroup);
  }, [meshToAnimate]);
  useFrame((state, dt) => mixer && mixer.update(dt));

  // setup Action
  const action = useMemo(() => {
    let a;
    animationClips.forEach((clip) => {
      if (clip.name === animationName) a = mixer.clipAction(clip);
    });
    return a;
  }, [animationClips, animationName, meshToAnimate]);

  // animation controls

  function playAnimation(action, secDuration) {
    if (!action) return;
    action.clampWhenFinished = true;
    action.setLoop(THREE.LoopOnce);
    if (!secDuration) action.setEffectiveTimeScale(1);
    else action.setDuration(secDuration);
    action.paused = false;
    action.play();
  }

  /**
   *
   *
   * Custom Logic For This Experience
   *
   *
   */

  const [domeAnimationState, setDomeAnimationState] = useAtom(dome_animation_state);

  useEffect(() => {
    setDomeAnimationState("up");
  }, []);

  // triggering the animation from state coming from UI
  useEffect(() => {
    switch (domeAnimationState) {
      case "up":
        playAnimation(action, 14);
        break;

      case "down":
        playAnimation(action, -14);
        break;

      default:
        break;
    }
  }, [domeAnimationState, action]);

  return null;
}
function AnimationController({ animationClips, animationName, meshToAnimate }) {
  // setup Mixer
  const mixer = useMemo(() => {
    return new THREE.AnimationMixer(meshToAnimate);
  }, [meshToAnimate]);
  useFrame((state, dt) => mixer && mixer.update(dt));

  // setup Action
  const action = useMemo(() => {
    let a;
    animationClips.forEach((clip) => {
      if (clip.name === animationName) a = mixer.clipAction(clip);
    });
    return a;
  }, [animationClips, animationName, meshToAnimate]);

  // animation controls

  function playAnimation(action, secDuration) {
    if (!action) return;
    action.clampWhenFinished = true;
    action.setLoop(THREE.LoopOnce);
    if (!secDuration) action.setEffectiveTimeScale(1);
    else action.setDuration(secDuration);
    action.paused = false;
    action.play();
  }

  function pauseAnimation(action) {
    if (!action) return;
    action.paused = true;
  }

  function setTime(action, newTime) {
    if (!action) return;
    action.time = newTime;
    action.paused = true;
    action.play();
  }

  /**
   *
   *
   * Custom Logic For This Experience
   *
   *
   */

  const [deskAnimationState] = useAtom(desk_animation_state);
  const [lookAtTarget] = useAtom(lookAtTarget_state);
  const isCameraAnimationActive_ref = useRef(true);

  // set the desk to starting position right off the bat
  useEffect(() => {
    // 2.56 time is equivilent to 35 inches, the starting height
    setTime(action, 2.56);
    syncCameraLookAt();
  }, [meshToAnimate]);

  // triggering the animation from state coming from UI
  useEffect(() => {
    switch (deskAnimationState) {
      case "still":
        pauseAnimation(action);
        break;

      case "up":
        playAnimation(action, 17);
        break;

      case "down":
        playAnimation(action, -17);
        break;

      default:
        break;
    }
  }, [deskAnimationState, action]);

  // observe desk's animation progress and report it back to UI
  const minHeight = 23.8;
  const startHeight = 35.0;
  const maxHeight = 49.4;
  const [, setDeskHeightState] = useAtom(desk_height_state);
  const [deskHeight, setDeskHeight] = useState(startHeight.toFixed(1));
  const totalDurationSec = useMemo(() => {
    return action.getClip().duration;
  }, [action]);

  useFrame(() => {
    if (meshToAnimate.name === "base-top-animated" && action) {
      const progress = action.time / totalDurationSec;
      const newHeight = ((maxHeight - minHeight) * progress + minHeight).toFixed(1);
      if (newHeight != deskHeight) setDeskHeight(newHeight);
    }
  });

  useEffect(() => {
    setDeskHeightState(deskHeight);
  }, [deskHeight]);

  // change camera controls lookAt so it's always focused on the desk
  useFrame((state, dt) => {
    if (deskAnimationState == "up" || deskAnimationState == "down" || isCameraAnimationActive_ref.current) {
      lookAtTarget.setY(deskHeight / 39.37 + 0.12);
      state.controls?.target?.lerp(lookAtTarget, 0.05);
    }
  });

  function syncCameraLookAt(time) {
    time = time || 1000;
    isCameraAnimationActive_ref.current = true;
    setTimeout(() => {
      isCameraAnimationActive_ref.current = false;
    }, time);
  }

  return null;
}

function MaterialController({ itemContainerId, materialName, productModelScene }) {
  const [, updateLoadingState] = useAtom(update_loading_count);

  const applicableItem = useActiveItem(itemContainerId);

  // update material
  useEffect(() => {
    // handles storage drawers
    if (materialName === "storage-drawers-variable") {
      let storageDrawersMat = findMeshByMaterial("storage-drawers-variable", productModelScene)?.material;
      if (!storageDrawersMat) return;
    }
    updateMaterial();
  }, [applicableItem, materialName, productModelScene]);

  async function updateMaterial() {
    let activeMat = getActiveMaterial();
    updateLoadingState(1);
    let newMat = await createNewMaterial(applicableItem.material_obj);
    activeMat.copy(newMat);
    activeMat = await applyMaterialProperties(getAsset, applicableItem.material_obj.properties, activeMat);
    newMat.dispose();
    // ----------------
    // custom code
    if (materialName === "desk-material-production" && applicableItem._id === "carbon_fiber") {
      // scales carbon fiber texture to be more accurate
      activeMat.map.repeat.setScalar(1.5);
      activeMat.map.needsUpdate = true;
    }
    // ----------------
    updateLoadingState(-1);
    dispatchLoadedEvent();
  }

  function getActiveMaterial() {
    let mat;
    productModelScene.traverse((node) => {
      if (node.isMesh && node.material.name === materialName) {
        mat = node.material;
      }
    });
    return mat;
  }

  const getAsset = useAssetLoader();
  async function createNewMaterial(material_obj) {
    let newMat;
    if (material_obj.type === "MeshStandardMaterial") newMat = new THREE.MeshStandardMaterial(material_obj.constructor);
    else if (material_obj.type === "MeshPhysicalMaterial") newMat = new THREE.MeshPhysicalMaterial(material_obj.constructor);
    else if (material_obj.type === "MeshBasicMaterial") newMat = new THREE.MeshBasicMaterial(material_obj.constructor);

    newMat.name = materialName;

    return newMat;
  }

  async function applyMaterialProperties(getAsset, material_props, material) {
    let texturePromises = [];

    Object.entries(material_props).forEach(([key, value]) => {
      // textures
      if (key.toLowerCase().includes("map")) {
        let texturePromise = new Promise(async (resolve) => {
          let texture = await getAsset(value);
          material[key] = texture;
          if (key.includes("metal")) material["roughnessMap"] = texture;
          else if (key.includes("rough")) material["metalnessMap"] = texture;
          resolve();
        });
        texturePromises.push(texturePromise);
      }

      // properties that need some preperation

      // arrays turn into vectors
      if (Array.isArray(value)) {
        material[key].fromArray(value);
      }
    });

    await Promise.all(texturePromises);

    material.needsUpdate = true;
    return material;
  }

  return null;
}

/**
 *
 * @param {string} itemContainerId, key in the itemsState.activeIds to know which item changes to react too
 * @param {Object3D} rootModelScene, scene object of the root model for this controller
 * @returns
 */
function MultiAttachmentModelController({ itemContainerId, rootModelScene }) {
  const objWithItemArray = useActiveItem(itemContainerId);
  const activeAttachmentItemsArray = objWithItemArray.array;

  // create attachmentNodesMap of references to all attachmentNodes in the model (attachmentNodes hold dynamic models)
  const attachmentNodesMap = useMemo(() => {
    let tempMap = {};
    // traverse the root model and find attachmentNodes (parent whose name.includes("attachmentNodes-group"))
    rootModelScene.traverse((node) => {
      if (node.parent?.name?.includes("attachmentNodes-group")) {
        tempMap[node.name] = node;
      }
    });
    return tempMap;
  }, [rootModelScene]);

  return (
    <>
      {Object.entries(attachmentNodesMap).map(([nodeId, attachmentNode]) => {
        return (
          <AttachmentNodeController
            key={nodeId}
            nodeId={nodeId}
            attachmentNode={attachmentNode}
            activeAttachmentItemsArray={activeAttachmentItemsArray}
            rootModelScene={rootModelScene}
          />
        );
      })}
    </>
  );
}

// 1 of these is created for each attachmentNode
function AttachmentNodeController({ nodeId, attachmentNode, activeAttachmentItemsArray, rootModelScene }) {
  const getAsset = useAssetLoader();
  const [isLoaded, setIsLoaded] = useState(true);

  const model_ref = useRef();

  // create loading mesh
  const loaderOpacityStart_ref = useRef(0.5);
  const loadingMesh = useMemo(() => {
    let geometry = new THREE.SphereGeometry(0.04);
    let material = new THREE.MeshBasicMaterial({ transparent: true, opacity: loaderOpacityStart_ref.current, color: "white" });
    let mesh = new THREE.Mesh(geometry, material);
    // make it render on top of everything else
    mesh.renderOrder = 999;
    mesh.material.depthTest = false;
    mesh.material.depthWrite = false;
    mesh.onBeforeRender = function (renderer) {
      renderer.clearDepth();
    };
    return mesh;
  }, []);

  // custom code: used to offset scale of attachmentNode (for caster_wheels) so loading mesh is scaled properly
  const loadingMeshContainer = useMemo(() => {
    const container = new THREE.Group();
    container.add(loadingMesh);
    return container;
  }, [loadingMesh]);

  /**
   *   1. get the active item this node observes + determine if this attachmentNode will be active
   */
  const nodesActiveItem = useMemo(() => {
    return activeAttachmentItemsArray?.find((item) => {
      return item.attachmentNodeIds.includes(nodeId);
    });
  }, [activeAttachmentItemsArray]);

  const isNodeActive = useMemo(() => {
    if (nodesActiveItem && (!nodesActiveItem.metaData?.isIncluded || nodesActiveItem.metaData?.is3dModelDynamicallyInjected)) return true;
    else return false;
  }, [nodesActiveItem]);

  /**
   * 2. clear node if not active
   */
  useEffect(() => {
    if (!isNodeActive) {
      attachmentNode.clear();
      // custom code
      if (attachmentNode.name === "caster_wheels") setCasterLoadingState(false);
    } else {
      // 3. if active, will this node have a new model
      if (attachmentNode?.children?.length === 0 || attachmentNode.userData.currentItemId != nodesActiveItem._id) setIsLoaded(false); // trigger executeLoadingSequence()
    }
  }, [nodesActiveItem]);

  /**
   * 4. display loading mesh at node position then replace it with the real model once loaded
   */
  useEffect(() => {
    if (!isLoaded) executeLoadingSequence();
  }, [isLoaded]);

  async function executeLoadingSequence() {
    if (attachmentNode.children.length > 0) attachmentNode.clear();
    // show loader at node position in the scene
    injectLoadingMesh();
    // load the new model
    model_ref.current = await loadModel(getAsset, nodesActiveItem.modelSrc);
    // apply any mods
    if (nodesActiveItem.mods) {
      applyModsToObject3D(attachmentNode, nodesActiveItem.mods, model_ref.current, rootModelScene);
      handleScaledAttachmentNode(); // custom code
    }
    // add shadows
    addShadows(model_ref.current);
    // custom code
    adjustRenderOrder(model_ref.current);
    // animated transition between loader disapearing and model appearing
    await animateModelBeingInjected();
    // if the model will be hidden then make the desk transparent for a few seconds
    if (nodesActiveItem.characteristics && nodesActiveItem.characteristics.includes("hiddenNode")) await highlightHiddenAccessory();
  }

  function injectLoadingMesh() {
    handleScaledAttachmentNode(); // custom code
    attachmentNode.add(loadingMeshContainer);
  }

  // custom code: scales the loading mesh opposite of attachment node so loading mesh always has scale of 1
  function handleScaledAttachmentNode() {
    loadingMeshContainer.scale.setScalar(1);
    loadingMeshContainer.scale.divide(attachmentNode.scale);
  }

  async function loadModel(getAsset, modelSrc) {
    let model = await getAsset(modelSrc);
    let modelClone = model.scene.clone();
    return modelClone;
  }

  function addShadows(scene) {
    scene.traverse((node) => {
      if (node.isMesh && !node.material.name.includes("transparent")) {
        node.castShadow = true;
        node.receiveShadow = true;
      }
    });
  }

  // make the transparent monitors render on top of desk since origin position causes this to not be the case sometimes
  function adjustRenderOrder(scene) {
    scene.traverse((node) => {
      if (node.isMesh && node.material.name === "transparentMonitor") {
        node.renderOrder = 1;
      }
    });
  }

  async function animateModelBeingInjected() {
    // make loader fade out
    loaderTween.start();
    await delay(loaderDuration - 50);
    attachmentNode.clear();

    // make new model fade in
    model_ref.current.scale.setScalar(0);
    attachmentNode.add(model_ref.current);
    modelTween.start();
    await delay(modelDuration);

    // update userData on item for node's memory
    attachmentNode.userData.currentItemId = nodesActiveItem._id;
    setIsLoaded(true);
  }

  // custom code
  // const [, setOutlinedMeshArray] = useAtom(outlined_mesh_array)
  async function highlightHiddenAccessory() {
    await delay(50);
    toggleDeskTransparency(0.2);
    // let outlinedObject = outlineNewAccessory();
    await delay(1200);
    // rootModelScene.remove(outlinedObject);
    // setOutlinedMeshArray([])
    toggleDeskTransparency(1);
  }

  // custom code: this creates an identical mesh to the hidden accessory mesh that was just added and creates the outline effect
  // const [, pushToOutlinedMeshArray] = useAtom(push_to_outlined_mesh_array)
  // const outlinedMesh_ref = useRef();
  // function outlineNewAccessory() {
  //   //  add outline mesh to root of scene (can't be nested below a mesh)
  //   outlinedMesh_ref.current = model_ref.current.clone();
  //   model_ref.current.getWorldPosition(outlinedMesh_ref.current.position);
  //   setTimeout(() => { model_ref.current.getWorldPosition(outlinedMesh_ref.current.position); }, 100);
  //   rootModelScene.add(outlinedMesh_ref.current);
  //   pushToOutlinedMeshArray(outlinedMesh_ref.current);
  //   return outlinedMesh_ref.current;
  // }

  // custom code
  function toggleDeskTransparency(newOpacity) {
    // make desk transparent for a few seconds so accessory is visible
    let matList = ["desk-material-production", "desk-material-bottom", "base-material-production", "grommet-black", "hand-control-silver", "touchScreen"];
    rootModelScene.traverse((node) => {
      if (node.isMesh && matList.includes(node.material.name)) {
        node.material.transparent = true;
        node.material.opacity = newOpacity;
        node.material.needsUpdate = true;
      }
    });
  }

  /**
   * Animations
   */

  const time_ref = useRef(0);
  const loaderDuration = 750;
  const loaderOpacity_ref = useRef(loaderOpacityStart_ref.current);
  const modelDuration = 100;
  const modelScale_ref = useRef(0);

  // loading indicator pulsing
  useFrame((state, dt) => {
    if (!isLoaded) {
      time_ref.current += dt;
      // loader scale
      let scale = (Math.sin(time_ref.current * 5) + 1) / 2 + 0.3;
      loadingMesh.scale.set(scale, scale, scale);
    }
  });

  var loaderTween = useMemo(() => {
    let t = new TWEEN.Tween(loaderOpacity_ref)
      .to({ current: 0 }, loaderDuration)
      .onUpdate(() => {
        loadingMesh.material.opacity = loaderOpacity_ref.current;
      })
      .onComplete(() => {
        loaderOpacity_ref.current = loaderOpacityStart_ref.current;
        loadingMesh.material.opacity = loaderOpacityStart_ref.current;
      });
    return t;
  }, []);

  const [casterLoadedState, setCasterLoadingState] = useAtom(caster_loaded_state);

  // tweens accessory model's scale from 0 to 1
  var modelTween = useMemo(() => {
    let t = new TWEEN.Tween(modelScale_ref)
      .to({ current: 1 }, modelDuration)
      .onStart(() => {
        // custom code: makes the desk move upwards to accomodate caster_wheels
        if (nodesActiveItem._id === "caster_wheels") setCasterLoadingState(true);
      })
      .onUpdate(() => {
        model_ref.current.scale.setScalar(modelScale_ref.current);
      })
      .onComplete(() => {
        modelScale_ref.current = 0;
      });
    return t;
  }, [nodesActiveItem]);

  return null;
}

/**
 *
 * helper functions
 *
 */

function applyModsToObject3D(object3D, mods, specificModelScene, rootModelScene) {
  // iterate through mods & apply mod to the object3D
  Object.entries(mods).forEach(([key, value]) => {
    // SPECIAL CASE: copyMaterial means we need to update material via copy
    if (key === "copyMaterial") {
      let matToUpdate = findMeshByMaterial(value.to, specificModelScene).material;
      let matNameToKeep = `${matToUpdate.name}`;
      let matToCopy = findMeshByMaterial(value.from, rootModelScene).material;
      matToUpdate.copy(matToCopy);
      matToUpdate.name = matNameToKeep;
    }
    // SPECIAL CASE: replaceMaterial means we need to update a mesh to use a different material
    if (key === "replaceMaterial") {
      let sourceMat = findMeshByMaterial(value.keeper, rootModelScene).material;
      let meshToReplace = findMeshByMaterial(value.replace, specificModelScene);
      if (meshToReplace) meshToReplace.material = sourceMat;
    }
    // CASE: arrays turn into vectors
    else if (Array.isArray(value)) {
      object3D[key].fromArray(value);
    }
    // pass in new value as is
    else {
      object3D[key] = value;
    }
  });
}

function findMeshByMaterial(materialName, scene) {
  let mesh;
  scene.traverse((node) => {
    if (node.isMesh && node.material.name == materialName) mesh = node;
  });
  return mesh;
}

function dispatchLoadedEvent() {
  document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
}

function delay(milliseconds) {
  return new Promise(function (resolve) {
    return setTimeout(resolve, milliseconds);
  });
}