import { useComponentsIndex } from "../../../hooks/useComponentsIndex";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const DeskTabs = ({ tabs, componentsStateObj }) => {
  const { deskModelIndex } = useComponentsIndex(componentsStateObj.state);

  const handleClick = (tab) => {
    const copyComponentsState = { ...componentsStateObj.state };
    copyComponentsState.array[deskModelIndex].desk_editing_status = tab._id;
    componentsStateObj.setState(copyComponentsState);
    pushGTMAnalyticsEvent({ event: "Tab Click - MojoDesk", tab: tab.displayName, page: "desks" });
  };
  try {
    const tabElements = tabs?.map((tab) => {
      const isActive = componentsStateObj.state.array[deskModelIndex].desk_editing_status === tab._id ? "active" : "";
      return (
        <div key={tab._id} className={isActive}>
          <p className={`mb-0 py-2 tab text-center ${isActive}`} onClick={() => handleClick(tab)}>
            {tab.displayName}
          </p>
        </div>
      );
    });

    return <div className="TabsContainer px-2 d-flex mx-auto justify-content-around align-items-center">{tabElements}</div>;
  } catch (err) {
    throw new Error(`Cannot read properties of undefined (reading 'map') at Desk Model Tabs: ${tabs}`)
  }

};
