import * as THREE from "three";
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { Environment } from "@react-three/drei";
// import useShadowHelper from "../../hooks/useShadowHelper.js";
// import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper";
import { RectAreaLightUniformsLib } from "three/examples/jsm/lights/RectAreaLightUniformsLib.js";
import { throttle } from "lodash";

import { useAssetLoader } from "../dataManagers/AssetSystem3d";
import { useActiveItem } from "../../modules/useActiveItem";

export default function EnvironmentController() {
  const camera = useThree(({ camera }) => camera);
  const scene = useThree(({ scene }) => scene);

  useEffect(() => {
    scene.add(camera); // so the camera is accessible in the three.js console
  }, []);

  return <MultiSceneController />;
}

function MultiSceneController() {
  const scene = useThree(({ scene }) => scene);

  const activeEnvItem = useActiveItem("environment_state");

  const backgroundEl = useMemo(() => {
    return document.getElementById("builder-scene-canvas-container");
  }, []);
  const presentationControlsEl = useMemo(() => {
    return scene.getObjectByName("PresentationControlsChild").parent;
  }, []);

  const modelContainer_ref = useRef();
  const envModel_ref = useRef();

  // lighting
  const lightContainer_ref = useRef();
  const directionalLight_ref = useRef();
  const [envMapPath, setEnvMapPath] = useState();
  const [lightObj, setLightObj] = useState();

  // init gameRoom lights
  useEffect(() => {
    RectAreaLightUniformsLib.init();

    // blue from shelves and baseboard
    const rectLightBlueTop = new THREE.RectAreaLight("blue", 0.25, 2.5, 1.8);
    rectLightBlueTop.position.set(0, 0.9, -0.6);
    rectLightBlueTop.lookAt(0, 0.85, 0);
    lightContainer_ref.current.add(rectLightBlueTop);
    // lightContainer_ref.current.add(new RectAreaLightHelper(rectLightBlueTop));

    // orange from logo
    const rectLightOrange = new THREE.RectAreaLight("#e34216", 1.5, 0.5, 0.3);
    rectLightOrange.position.set(0, 1.6, -0.5);
    rectLightOrange.lookAt(0, 1, 0);
    lightContainer_ref.current.add(rectLightOrange);
    // lightContainer_ref.current.add(new RectAreaLightHelper(rectLightOrange));

    turnOffGameRoomLights();
  }, []);

  // when shopper changes env, begin process of switching env's
  useEffect(() => {
    beginNewEnvSequence();
  }, [activeEnvItem._id]);

  async function beginNewEnvSequence() {
    switchBackgroundColor();
    switchLighting();
    switchEnvMap();
    await loadEnvModel();
    injectNewModel();
  }

  function switchBackgroundColor() {
    switch (activeEnvItem._id) {
      case "home_office":
        backgroundEl.style.background = "#cfc5bb";
        break;

      case "game_room":
        backgroundEl.style.background = "#201514";
        break;

      case "executive_office":
        backgroundEl.style.background = "#e7e7e7";
        break;

      default:
        break;
    }
  }

  function switchLighting() {
    switch (activeEnvItem._id) {
      case "home_office":
        turnOffGameRoomLights();
        setLightObj({
          directionalLightColor: "#e2e2e2",
          ambientLightColor: "white",
          ambientLightIntensity: 0.5,
        });
        break;

      case "game_room":
        turnOnGameRoomLights();
        setLightObj({
          directionalLightColor: "#493a2c",
          ambientLightColor: "white",
          ambientLightIntensity: 1,
        });
        break;

      case "executive_office":
        turnOffGameRoomLights();
        setLightObj({
          directionalLightColor: "#e2e2e2",
          ambientLightColor: "white",
          ambientLightIntensity: 0.5,
        });
        break;

      default:
        break;
    }
  }

  function switchEnvMap() {
    switch (activeEnvItem._id) {
      case "home_office":
        setEnvMapPath("/mojo-desk-assets/scenery/textures/homeOffice/envMap");
        break;

      case "game_room":
        setEnvMapPath("/mojo-desk-assets/scenery/textures/gameRoom/envMap");
        break;

      case "executive_office":
        setEnvMapPath("/mojo-desk-assets/scenery/textures/execOffice/envMap");
        break;

      default:
        break;
    }
  }

  function turnOnGameRoomLights() {
    lightContainer_ref.current.traverse((node) => {
      if (node.type && node.type === "RectAreaLight") {
        node.visible = true;
      }
    });
  }
  function turnOffGameRoomLights() {
    lightContainer_ref.current.traverse((node) => {
      if (node.type && node.type === "RectAreaLight") {
        node.visible = false;
      }
    });
  }

  /**
   *
   * make the env invisible if camera goes behind the wall
   *
   */

  const targetOpacity_ref = useRef(1);
  const normalizedRotation_ref = useRef();
  const envMaterial_ref = useRef();

  function setEnvMaterial(newEnv) {
    newEnv.traverse((node) => {
      if (node.isMesh) envMaterial_ref.current = node.material;
    });
  }

  const handleEnvVisibility = useCallback(
    throttle(() => {
      if (activeEnvItem._id === "executive_office") return;
      // set target opacity of env depending upon camera position
      if (isCameraBehindWall()) targetOpacity_ref.current = 0;
      else {
        if (envMaterial_ref.current) envMaterial_ref.current.visible = true;
        targetOpacity_ref.current = 1;
      }
    }, 200),
    [activeEnvItem._id]
  );

  function isCameraBehindWall() {
    normalizedRotation_ref.current = Math.abs(presentationControlsEl.rotation.y % (Math.PI * 2));
    if (normalizedRotation_ref.current > 1.75 && normalizedRotation_ref.current < 4.55) return true;
    else return false;
  }

  useFrame(handleEnvVisibility);

  // lerp opacity
  useFrame((state, dt) => {
    if (activeEnvItem._id === "executive_office" || !envModel_ref.current) return;
    if (envMaterial_ref.current?.opacity) {
      envMaterial_ref.current.opacity = THREE.MathUtils.lerp(envMaterial_ref.current.opacity, targetOpacity_ref.current, dt * 10);
      if (targetOpacity_ref.current === 0 && envMaterial_ref.current.opacity <= 0.05) envMaterial_ref.current.visible = false;
    }
  });

  const getAsset = useAssetLoader();
  async function loadEnvModel() {
    let newModel = await getAsset(activeEnvItem.modelSrc);
    if (newModel.scene) {
      envModel_ref.current = newModel.scene;
      setTransparency(envModel_ref.current);
    }
  }

  function injectNewModel() {
    modelContainer_ref.current.clear();
    modelContainer_ref.current.add(envModel_ref.current);
    setEnvMaterial(envModel_ref.current);
    dispatchLoadedEvent();
  }

  function setTransparency(scene) {
    scene.traverse((node) => {
      if (node.isMesh) {
        node.renderOrder = -1; // make env render behind everything else
        if (activeEnvItem._id != "executive_office") node.material.transparent = true;
      }
    });
  }

  // signaling to the loading screen we're ready to go
  function dispatchLoadedEvent() {
    document.dispatchEvent(new CustomEvent("EnvironmentAssetsLoaded"));
  }

  return (
    <>
      <group ref={modelContainer_ref}>{/* env model gets injected here */}</group>

      {/* lighting */}

      {lightObj && (
        <>
          <directionalLight
            ref={directionalLight_ref}
            args={[lightObj.directionalLightColor, 1]}
            position={[0, 3.5, 0.4]}
            castShadow={true}
            shadowDarkness={0.2}
            shadow-radius={12}
            shadow-normalBias={0.001}
            shadow-mapSize-width={2048}
            shadow-mapSize-height={1024}
            shadow-camera-near={0.5}
            shadow-camera-far={3.5}
            shadow-camera-left={-1}
            shadow-camera-right={1}
            shadow-camera-top={0.6}
            shadow-camera-bottom={-0.7}
          />
          <ambientLight args={[lightObj.ambientLightColor, lightObj.ambientLightIntensity]} />
        </>
      )}

      {envMapPath && (
        <Environment
          background={false}
          files={[
            `${envMapPath}/px.png`,
            `${envMapPath}/nx.png`,
            `${envMapPath}/py.png`,
            `${envMapPath}/ny.png`,
            `${envMapPath}/pz.png`,
            `${envMapPath}/nz.png`,
          ]}
        />
      )}

      {/* rect lights get injected here */}
      <group ref={lightContainer_ref}></group>
    </>
  );
}
