// returns an object to use for bootstraps grid layout

export const getCardLayoutInfo = (cardPerRow) => {
  const layoutInfo = {
    1: () => ({ xs: 8, md: 10, width: "16rem" }),
    2: () => ({ xs: 6, md: 6, width: "auto" }),
    3: () => ({ xs: 4, md: 4, width: "auto" }),
  };
  return layoutInfo[cardPerRow]();
};
