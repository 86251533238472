import { useState, useEffect } from "react";
import { useRouter } from "wouter";

const currentLocation = () => {
  return window.location.hash || "#/";
};

const navigate = (to) => {
  window.location.hash = to;
};

export const useHashLocation = () => {
  const [loc, setLoc] = useState(currentLocation());

  useEffect(() => {
    // this function is called whenever the hash changes
    const handler = () => setLoc(currentLocation());

    // subscribe to hash changes
    window.addEventListener("hashchange", handler);
    return () => window.removeEventListener("hashchange", handler);
  }, []);

  return [loc, navigate];
};

export const useHashRoute = (pattern) => {
  const [path] = useHashLocation();
  return useRouter().matcher(pattern, path);
};
