
import sift from "sift";
import { Tabs } from "../Tabs/Tabs";
import { useComponentsIndex } from "../../../hooks/useComponentsIndex";
import { CardElementMaterials } from "../CardElement/CardElementMaterials";

const itemsCache = {};
export const MaterialsScreen = ({ componentsStateObj, itemsStateObj }) => {
  const disableTabs = ["three_leg_corner"].includes(itemsStateObj.state.activeObjs.desk_model.section);
  const { materialsIndex } = useComponentsIndex(componentsStateObj.state);
  const activeMaterialSectionId = componentsStateObj.state.array[materialsIndex].material_editing_status;

  const getMaterials = (section) => {
    // check if query is already cached
    if (itemsCache[section]) {
      return itemsCache[section];
    }

    // query is not cached, so fetch it, sort it, and add it to the cache
    itemsCache[section] = itemsStateObj.state.array
      .filter(sift({ _id: { $in: componentsStateObj.state.activeObj.items }, section: { $eq: section } }))
      .sort((a, b) => {
        return componentsStateObj.state.activeObj.items?.indexOf(a._id) - componentsStateObj.state.activeObj.items?.indexOf(b._id);
      });
    return itemsCache[section];
  };

  return (
    <div className="mt-3">
      {!disableTabs && <Tabs tabs={componentsStateObj.state.activeObj.sections} componentsStateObj={componentsStateObj} />}
      <CardElementMaterials itemsStateObj={itemsStateObj} basesArray={getMaterials(activeMaterialSectionId)} materialEditingStatus={activeMaterialSectionId} />

      {activeMaterialSectionId === "base_material" && (
        <div className="d-flex justify-content-center">
          <iframe
            width="320"
            height="210"
            src="https://www.youtube.com/embed/SDLaKLYbdAg"
            title="Adjustable Frame"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};
