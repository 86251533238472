import { useCallback } from "react";
import { getStepperActiveIndex } from "../../../modules/getStepperActiveIndex";
import { Boop } from "../Animations/Boop";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const Navigation = ({ componentsStateObj }) => {
  const activeComponentIndex = getStepperActiveIndex(componentsStateObj.state);

  const handlePrevious = useCallback(() => {
    if (activeComponentIndex != 0) {
      componentsStateObj.setActiveId(componentsStateObj.state.array[activeComponentIndex - 1]._id);

      pushGTMAnalyticsEvent({ event: "Navigation Flag Button Click - MojoDesk", flagLabel: "Prev", page: componentsStateObj.state.activeId.split("_")[0] });
    }
  }, [componentsStateObj.state.activeId]);

  const handleNext = useCallback(() => {
    componentsStateObj.setActiveId(componentsStateObj.state.array[activeComponentIndex + 1]._id);

    pushGTMAnalyticsEvent({
      event: "Navigation Flag Button Click - MojoDesk",
      flagLabel: activeComponentIndex === 2 ? "Cart" : "Next",
      page: componentsStateObj.state.activeId,
    });
  }, [componentsStateObj.state.activeId]);

  const stickyNavRef = useCallback((node) => {
    if (typeof IntersectionObserver === "undefined") return;
    // apply className isSticking to navigation when it intersects with the top of the page
    const observer = new IntersectionObserver(([e]) => e.target.classList.toggle("isSticking", e.intersectionRatio < 0.99), { threshold: [0.99], root: null });

    if (node && observer) {
      observer.observe(node);
    }
  }, []);

  return (
    <div ref={stickyNavRef} className="navigation sticky pt-1 pb-1 mt-1 mb-2 d-flex align-items-center justify-content-center">
      <Boop boopType="scale" scale="1.1" timing={200}>
        <ImgWithFallback
          src="/images/nav_btn_flags/prev-btn-flag.webp?v=1"
          fallback="/images/nav_btn_flags/prev-btn-flag.jpg?v=1"
          alt="previous button"
          className={`mx-3 ${activeComponentIndex === 0 && `disable`}`}
          onClick={handlePrevious}
        />
      </Boop>

      <h4 className="text-center pt-1 my-auto">{`Select ${componentsStateObj.state.activeObj.displayName}`}</h4>

      <Boop boopType="scale" scale="1.1" timing={200}>
        <ImgWithFallback
          src={activeComponentIndex === 2 ? "/images/nav_btn_flags/cart-btn-flag.webp?v=1" : "/images/nav_btn_flags/next-btn-flag.webp?v=1"}
          fallback={activeComponentIndex === 2 ? "/images/nav_btn_flags/cart-btn-flag.jpg?v=1" : "/images/nav_btn_flags/next-btn-flag.jpg?v=1"}
          alt="next button"
          className="mx-3"
          onClick={handleNext}
        />
      </Boop>
    </div>
  );
};
