/** Loops through itemsState.activeObjs to organize ttCartData
 * ttCartData then gets fed into cards
 */

export const getShoppingCartData = (componentsState, itemsState, componentsIndexObj) => {
  // drill into component sections to get pricing
  const [deskModelSection] = componentsState.array[componentsIndexObj.deskModelIndex].sections.filter(
    (item) => item._id === itemsState.activeObjs.desk_model.section
  );

  let ttCartData = []; // detailed data of each product added to cart. Used for google analytics
  let shopifyIds = []; // shopify product data used to submit to the cart on client's website
  let shopifyBuilderSummary = {}; // contains comprehensive written summary of product that's shown in the cart on clients website

  const iterate = (itemsStateActiveObjs) => {
    Object.keys(itemsStateActiveObjs).forEach((key) => {
      let cartItemObj = {};

      // drill into nested objects in itemsState
      if (typeof itemsStateActiveObjs[key] === "object" && itemsStateActiveObjs[key] !== null) {
        iterate(itemsStateActiveObjs[key]);
      }

      /**
       *
       * id, name, price, and quantity are data layer variables for google analytcs.
       * DONT REMOVE OR CHANGE THEM
       *
       */

      if (key === "desk_model") {
        cartItemObj.id = itemsStateActiveObjs[key]._id;
        cartItemObj.title = "Desk Model";
        cartItemObj.name = deskModelSection.displayName;
        cartItemObj.itemDescription = `${itemsStateActiveObjs[key].displayName.split("x")[0]}" wide x ${itemsStateActiveObjs[key].displayName.split("x")[1]
          }" deep`;
        cartItemObj.previewImg = itemsStateActiveObjs[key]?.thumbnail ?? null;
        cartItemObj.price = itemsStateActiveObjs[key]?.price;
        cartItemObj.section = itemsStateActiveObjs[key].section;
        cartItemObj.className = "deskModel";
        cartItemObj.quantity = 1;
        cartItemObj.category = "DeskModel";
        ttCartData.push(cartItemObj);

        // some shopify values are arrays, some are single objects, so handle that here
        if (Array.isArray(itemsStateActiveObjs[key].shopify)) shopifyIds.push(...itemsStateActiveObjs[key].shopify);
        else shopifyIds.push(itemsStateActiveObjs[key].shopify);

        shopifyBuilderSummary["Desk Model"] = deskModelSection.displayName;
        shopifyBuilderSummary["Work Surface Size"] = cartItemObj.itemDescription;
      } else if (key === "desk_material") {
        cartItemObj.id = itemsStateActiveObjs[key]._id;
        cartItemObj.title = "Desk Surface Color";
        cartItemObj.name = itemsStateActiveObjs[key].displayName;
        cartItemObj.itemDescription = null;
        cartItemObj.previewImg = itemsStateActiveObjs[key].thumbnail;
        cartItemObj.price = itemsStateActiveObjs[key]?.price ?? null;
        cartItemObj.section = itemsStateActiveObjs[key].section;
        cartItemObj.className = "deskMaterial";
        cartItemObj.quantity = 1;
        cartItemObj.category = "Surface Material";
        cartItemObj.shopify = itemsStateActiveObjs[key].shopify[itemsState.activeIds.desk_model._id];
        cartItemObj.isIncluded = itemsStateActiveObjs[key].metaData?.isIncluded;
        ttCartData.push(cartItemObj);

        // some shopify values are arrays, some are single objects, so handle that here
        if (Array.isArray(itemsStateActiveObjs[key].shopify[itemsState.activeIds.desk_model._id]))
          shopifyIds.push(...itemsStateActiveObjs[key].shopify[itemsState.activeIds.desk_model._id]);
        else shopifyIds.push(itemsStateActiveObjs[key].shopify[itemsState.activeIds.desk_model._id]);

        shopifyBuilderSummary["Surface Finish"] = itemsStateActiveObjs[key].displayName;
      } else if (key === "base_material") {
        cartItemObj.id = itemsStateActiveObjs[key]._id;
        cartItemObj.title = "Base Color";
        cartItemObj.name = itemsStateActiveObjs[key].displayName;
        cartItemObj.itemDescription = null;
        cartItemObj.previewImg = itemsStateActiveObjs[key].thumbnail;
        cartItemObj.price = itemsStateActiveObjs[key].price;
        cartItemObj.section = itemsStateActiveObjs[key].section;
        cartItemObj.className = "baseMaterial";
        cartItemObj.quantity = 1;
        cartItemObj.category = "Base Material";
        cartItemObj.shopify = itemsStateActiveObjs[key].shopify;
        cartItemObj.isIncluded = itemsStateActiveObjs[key].metaData?.isIncluded;
        ttCartData.push(cartItemObj);

        // some shopify values are arrays, some are single objects, so handle that here
        if (Array.isArray(itemsStateActiveObjs[key].shopify)) shopifyIds.push(...itemsStateActiveObjs[key].shopify);
        else if (itemsState.activeObjs["desk_model"]._id.includes("dome") || itemsState.activeObjs["desk_model"]._id.includes("battle_station"))
          shopifyIds.push(itemsStateActiveObjs[key].shopify.included);
        else if (itemsState.activeObjs["desk_model"]._id.includes("cubicle_rectangle_straight"))
          shopifyIds.push(itemsStateActiveObjs[key].shopify.straightFrontEdge);
        else shopifyIds.push(itemsStateActiveObjs[key].shopify.default);

        shopifyBuilderSummary["Base Color"] = itemsStateActiveObjs[key].displayName;
      }

      // accessories are optional, so make sure they exist
      else if (key === "accessories" && itemsStateActiveObjs[key].array.length > 0) {
        // loop through acccessories array
        itemsStateActiveObjs[key].array.forEach((accessory, index) => {
          cartItemObj = {};
          cartItemObj.id = accessory._id;
          cartItemObj.title = "Accessory";
          cartItemObj.name = accessory.displayName;
          cartItemObj.itemDescription =
            accessory._id === "storage_drawers" ? `Surface Color: ${itemsState.activeObjs.materials.desk_material.displayName}` : null;
          cartItemObj.previewImg = accessory.thumbnail;
          cartItemObj.price = accessory.price;
          cartItemObj.section = accessory.section;
          cartItemObj.className = "accessories";
          cartItemObj.quantity = 1;
          cartItemObj.category = "Accessory";
          cartItemObj.shopify = accessory.shopify;
          cartItemObj.isIncluded = accessory.metaData?.isIncluded;
          ttCartData.push(cartItemObj);

          // storage drawers custom logic
          if (accessory._id === "storage_drawers") {
            // surface color
            shopifyIds.push(accessory.shopify[itemsStateActiveObjs.materials.desk_material._id]);
            // base
            shopifyIds.push(accessory.shopify["base"]);
          }

          // combo monitors custom logic
          else if (Array.isArray(accessory.shopify)) {
            shopifyIds.push(...accessory.shopify);
          }

          // included accessories
          else if (accessory.metaData?.isIncluded) {
            if (accessory.shopify.included) {
              shopifyIds.push(accessory.shopify.included);
            } else {
              shopifyIds.push(accessory.shopify);
            }
          }

          // all other accessories
          else shopifyIds.push(accessory.shopify.default || accessory.shopify);

          shopifyBuilderSummary[`Accessory ${index + 1}`] = accessory.displayName;
        });
      }
    });
  };

  iterate(itemsState.activeObjs);

  return [ttCartData, shopifyIds.reverse(), shopifyBuilderSummary];
};

// rearranges the array to present cards in certain order
// Array.prototype.reorderArray = function (from, to) {
//   this.splice(to, 0, this.splice(from, 1)[0]);
// };
