import { useCallback } from "react";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { getStepperActiveIndex } from "../../../modules/getStepperActiveIndex";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const BottomNavigation = ({ componentsStateObj }) => {
  const activeComponentIndex = getStepperActiveIndex(componentsStateObj.state);

  const handlePrevious = useCallback(() => {
    if (activeComponentIndex !== 0) {
      componentsStateObj.setActiveId(componentsStateObj.state.array[activeComponentIndex - 1]._id);

      pushGTMAnalyticsEvent({ event: "Bottom Navigation Button Click - MojoDesk", flagLabel: "Previous", page: componentsStateObj.state.activeId });
    }
  }, [componentsStateObj.state.activeId]);

  const handleNext = useCallback(() => {
    componentsStateObj.setActiveId(componentsStateObj.state.array[activeComponentIndex + 1]._id);

    pushGTMAnalyticsEvent({
      event: "Bottom Navigation Button Click - MojoDesk",
      flagLabel: activeComponentIndex === 2 ? "Cart" : "Next",
      page: componentsStateObj.state.activeId,
    });
  }, [componentsStateObj.state.activeId]);

  return (
    <div className="d-flex justify-content-around mt-5 mb-2">
      <RectangleButton
        label="Prev"
        buttonType="default"
        onClickHandler={handlePrevious}
        customClass={`top-0 bottomNavigationBtn ${activeComponentIndex === 0 && `disabled`}`}
      />
      <RectangleButton
        label={activeComponentIndex === 2 ? "Cart" : "Next"}
        buttonType="default"
        onClickHandler={handleNext}
        customClass="top-0 bottomNavigationBtn"
      />
    </div>
  );
};
