
import sift from "sift";
import { Line } from "../Line/Line";
import { DeskTabs } from "../Tabs/DeskModelTabs";
import { CardElementDesks } from "../CardElement/CardElementDesks";
import { DeskModelSection } from "../DeskModelSection/DeskModelSection";
import { useComponentsIndex } from "../../../hooks/useComponentsIndex";

const itemsCache = {};
export const DeskModelsScreen = ({ componentsStateObj, itemsStateObj }) => {
  const { deskModelIndex } = useComponentsIndex(componentsStateObj.state);
  const activeDeskSectionId = componentsStateObj.state.array[deskModelIndex].desk_editing_status;
  const getDesks = (section) => {
    // query is not cached, so fetch it, sort it, and add it to the cache
    itemsCache[section] = itemsStateObj.state.array
      .filter(sift({ _id: { $in: componentsStateObj.state.activeObj.items }, section: { $eq: section._id } }))
      .sort((a, b) => {
        return componentsStateObj.state.activeObj.items?.indexOf(a._id) - componentsStateObj.state.activeObj.items?.indexOf(b._id);
      });
    return itemsCache[section];
  };
  return (
    <div className="mt-3">
      {<DeskTabs tabs={componentsStateObj.state.activeObj.ParentSections} componentsStateObj={componentsStateObj} />}
      {
        (() => {
          try {
            return componentsStateObj?.state?.activeObj?.ParentSections?.map((parentSection, index) => (
              parentSection?.children?.map((section) => (
                parentSection._id === activeDeskSectionId && (
                  <div key={section._id}>
                    <DeskModelSection title={section.displayName} description={section.description}>
                      <CardElementDesks deskData={getDesks(section)} itemsStateObj={itemsStateObj} />

                      {index !== componentsStateObj.state.activeObj.sections.length - 1 && <Line color="#BCBCBC" height="1px" />}
                    </DeskModelSection>
                  </div>
                )
              ))
            ))
          } catch (err) {
            throw new Error(`Cannot read properties of undefined (reading 'map') at Desk Model Screen: ${componentsStateObj?.state?.activeObj?.ParentSections}`)
          }
        })()
      }
    </div>
  );
};
