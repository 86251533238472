{
  /* Uses .shared-scene-sizing to mimic the scene's canvas. It's on top of it but "see through". This allows us to overlay UI on the scene  */
}
export const HUD = ({ children }) => {
  return (
    <div id="HUD" className="HUD shared-scene-sizing scene-canvas-cover" style={{ zIndex: 1002, pointerEvents: "none" }}>
      {children}
    </div>
  );
};
