import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const DeskHeightController = ({ deskAnimationStateObj, deskHeightStateObj }) => {
  const animate = (direction) => deskAnimationStateObj.setState(direction);
  const stopAnimation = () => deskAnimationStateObj.setState("still");

  return (
    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-details-deskHeightController"> Move Desk Up/Down </Tooltip>}>
      <div className="DeskHeightController d-flex flex-column ms-2">
        <p className="mb-0 mx-auto">Desk Height</p>
        <div className="d-flex justify-content-around align-items-center">
          <img
            src="/images/arrowButton.svg"
            onPointerDown={() => animate("up")}
            onPointerUp={stopAnimation}
            onDragLeave={stopAnimation}
            alt="arrow"
            onClick={() => pushGTMAnalyticsEvent({ event: "Desk Height Controller - MojoDesk", direction: "up" })}
          />
          <h3 className="m-0">{deskHeightStateObj.state}</h3>
          <img
            className="rotate"
            onPointerDown={() => animate("down")}
            onPointerUp={stopAnimation}
            onDragLeave={stopAnimation}
            src="/images/arrowButton.svg"
            alt="arrow"
            onClick={() => pushGTMAnalyticsEvent({ event: "Desk Height Controller - MojoDesk", direction: "down" })}
          />
        </div>
      </div>
    </OverlayTrigger>
  );
};
