import { useState } from "react";
import { BuilderScreen } from "./UI/BuilderScreen/BuilderScreen";
import { BuilderUI } from "./UI/BuilderUI/BuilderUI";
import Scene from "./r3f/Scene.js";
import { isIE } from "react-device-detect";
import { OverlayBadBrowsers } from "./UI/OverlayBadBrowsers/OverlayBadBrowsers";

export default function ProductBuilder() {
  const [isOverlayVisible, setIsOverlayVisible] = useState(isIE);

  return (
    <BuilderScreen>
      {isOverlayVisible && <OverlayBadBrowsers isOverlayVisible={isOverlayVisible} setIsOverlayVisible={setIsOverlayVisible} />}
      {!isOverlayVisible && (
        <>
          <BuilderUI />
          <Scene />
        </>
      )}
    </BuilderScreen>
  );
}
