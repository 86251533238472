import { useState, useMemo } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Boop } from "../Animations/Boop";
import Modal from "react-bootstrap/Modal";
import { useTransition, animated } from "react-spring";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { getCardLayoutInfo } from "../../../modules/getCardLayoutInfo";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import "./styles.scss";

export const CardElementDesks = ({ deskData, itemsStateObj }) => {
  const [show, setShow] = useState(false);
  const [modalDetails, setModalDetails] = useState({});
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const cardLayoutInfo = getCardLayoutInfo(3);

  // get base prices to add to the desk top price
  const defaultBasePrice = useMemo(() => itemsStateObj.state.array?.find((item) => item._id === "black_base")?.price, []);
  const threeLegBasePrice = useMemo(() => itemsStateObj.state.array?.find((item) => item._id === "black_base_3legs")?.price, []);

  const handleClick = (cardId) => {
    let copy = { ...itemsStateObj.state.activeIds };
    copy.desk_model._id = cardId; // update new desk model
    copy.accessories.array = []; // clear the accessories array  (custom code)
    itemsStateObj.setState(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - MojoDesk",
      itemSelected: cardId,
      page: "desk_model",
    });
  };
  const ProductDetailsModal = ({ show, modalDetails }) => (
    <Modal className="ProductDetailsModal" show={show} size="md" style={{ zIndex: 2002 }} onHide={handleCloseModal} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalDetails.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          {modalDetails?.youtubeSrc && (
            <iframe
              className="mx-auto my-4"
              width="85%"
              height="250"
              src={modalDetails.youtubeSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          )}
          {modalDetails?.image && (
            <ImgWithFallback src={modalDetails.image} fallback={`${modalDetails.image.split(".")[0]}.png`} alt={modalDetails.title} className="mx-auto my-4" />
          )}
          <h5 className="mb-2">Description</h5>
          <p className="description">{modalDetails.description}</p>
          <a href={modalDetails.productPage} target="_blank" rel="noopener noreferrer" className="btn btn-primary mx-auto mt-1 mt-md-4">
            Learn More
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );

  // returns an array with animation style data on each item
  const dataToDisplayTransitionArray = useTransition(deskData, {
    trail: 100,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  const handleSeeProductDetailsClick = (e, _modalDetails) => {
    setModalDetails({ ..._modalDetails });
    handleShowModal();
    // if(EmbedController.isEmbedded) EmbedController.sendGTMAnalyticsEvent({ 'event': 'Product Details Click - MGP' });
  };
  return (
    <Row>
      {dataToDisplayTransitionArray((style, card, key, index) => {
        const is3LegCorner = card.section?.includes("three_leg_corner");
        const isActive = itemsStateObj.state.activeIds.desk_model._id === card._id;
        const basePrice = is3LegCorner ? threeLegBasePrice : defaultBasePrice;

        return (
          <Col xs={is3LegCorner ? 6 : cardLayoutInfo.xs} md={is3LegCorner ? 6 : cardLayoutInfo.md} key={card._id} className="mx-auto px-2">
            <animated.div style={style}>
              <Card
                className={`itemsCard mb-3 ${isActive ? "active" : ""}`}
                style={{ width: cardLayoutInfo.width }}
                onClick={() => handleClick(card._id)}
                disabled
              >
                {isActive ? <img className="checkMark" alt="check mark" src="/images/checkMark.svg" /> : null}
                <div className="d-flex">
                  {card?.metaData?.isNew && !isActive && (
                    <div className="me-auto">
                      <img alt="new" className="cardBadge" src="/images/badgeNew.png" />
                    </div>
                  )}
                  {card?.metaData?.onSale && !isActive && (
                    <div className="me-auto">
                      <img alt="new" className="cardBadge" src="/images/badgeSale.png" />
                    </div>
                  )}

                  <div className="price ms-auto px-2">{`$${
                    card?._id.includes("mojo_dome") || card?._id.includes("battle_station") ? card.price : card.price + basePrice
                  }`}</div>
                </div>
                <div className="mx-auto my-1 d-flex">
                  <ImgWithFallback className="thumbnail" src={card.thumbnail[0]} fallback={card.thumbnail[1] || card.thumbnail[0]} alt={card.displayName} />
                </div>
                <div className="body py-1 px-2 px-sm-3 d-flex justify-content-between align-items-center">
                  <div className="flex-column">
                    <p className="size mb-0 text-center">Wide</p>
                    <p className="spec mb-0 text-center">{`${card.displayName.split("x")[0]}"`}</p>
                  </div>
                  <div className="flex-column">
                    <p className="size mb-0 text-center">Deep</p>
                    <p className="spec mb-0 text-center">{`${card.displayName.split("x")[1]}"`}</p>
                  </div>
                </div>
                {card?.modalDetails && (
                  <div className="d-flex mx-auto mb-2">
                    <Boop boopType="scale" scale={1.03} timing={200}>
                      <button id="modalButton" onClick={(e) => handleSeeProductDetailsClick(e, card.modalDetails)}>
                        more info
                      </button>
                    </Boop>
                  </div>
                )}
                {card?.title && <div className="card-title">{card.title}</div>}
              </Card>
            </animated.div>
          </Col>
        );
      })}
      <ProductDetailsModal show={show} modalDetails={modalDetails} />
    </Row>
  );
};
