import EmbedController from "../../../../embed/EmbedController";
import { CircleButton } from "../CircleButton/CircleButton";
import { Boop } from "../Animations/Boop";
import { isMobile } from "react-device-detect";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const WidgetShare = ({ page }) => {
  // content to show in overlay on btn click
  const tooltipContent = {
    id: "share-tooltip",
    onHover: "Share Build",
    onClick: isMobile ? "Desk Copied" : "Custom desk link copied to clipboard",
    placement: isMobile ? "bottom" : "right",
    defaultShow: false,
  };

  const handleClick = () => {
    navigator.clipboard.writeText(EmbedController.isEmbedded ? EmbedController.parentUrl : window.location.href);

    pushGTMAnalyticsEvent({ event: "Share Click - MojoDesk", page: page });
  };

  return (
    <Boop boopType="scale" scale={1.04} timing={200}>
      <div onClick={handleClick} className="shareButton mx-1">
        <CircleButton circleColor="#FFFFFF" circleSize="40px" tooltipContent={tooltipContent} icon={"/images/iconShare.png"} />
      </div>
    </Boop>
  );
};
