/**
 * Calculates the final price of the configuration
 */

export const getFinalPrice = (productsState, cartData) => {
  let finalPrice = 0;
  cartData.forEach((item, index) => {
    if (!item.isIncluded) finalPrice += parseFloat(item.price);
  });
  return finalPrice.toFixed(2);
};
