const AWS = require("aws-sdk");
const { v4: uuidv4 } = require("uuid");

// TODO: change this per project
const clientFolder = "mojo-desk";

const spacesEndpoint = new AWS.Endpoint("sfo2.digitaloceanspaces.com");

const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: process.env.REACT_APP_SPACES_KEY,
  secretAccessKey: process.env.REACT_APP_SPACES_SECRET,
});

const s3Params = {
  Bucket: "workroomvr",
  ACL: "public-read",
};

export async function uploadImageToDigitalOcean(imageBase64) {
  const savePath = `product-screenshots/${clientFolder}/${uuidv4()}.png`;

  const imageBuffer = Buffer.from(imageBase64.replace(/^data:image\/\w+;base64,/, ""), "base64");

  const params = {
    ...s3Params,
    Key: savePath,
    Body: imageBuffer,
    ContentEncoding: "base64",
    ContentType: "image/png",
  };

  try {
    const uploadResult = await s3.upload(params).promise();
    console.log(`uploadResult.Location ${uploadResult.Location}`);
    return uploadResult.Location;
  } catch (error) {
    console.log(error);
    return `https://workroomvr.sfo2.digitaloceanspaces.com/product-screenshots/${clientFolder}/default.jpg`;
  }
}
