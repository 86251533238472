import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import { BottomNavigation } from "../Navigation/BottomNavigation";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useComponentsIndex } from '../../../hooks/useComponentsIndex';
// import { getStepperActiveIndex } from '../../../modules/getStepperActiveIndex';
import "./styles.scss";

export const BuildSection = ({ componentsStateObj, children }) => {
  // const { deskModelIndex, materialsIndex } = useComponentsIndex(componentsStateObj.state);
  // const activeComponentIndex = getStepperActiveIndex(componentsStateObj.state);
  // const displayBottomNav = activeComponentIndex === deskModelIndex || activeComponentIndex === materialsIndex;
  const ref = useRef();
  const isUiPortraitOrientation = useMediaQuery("(orientation: portrait)");

  useEffect(() => {
    if (ref.current) ref.current.scrollTop = 0;
  }, [componentsStateObj.state.activeId]);

  return (
    <Container fluid ref={ref} id="BuildSection" className="Drawer">
      {children}
      {componentsStateObj.state.activeId !== "shopping_cart" && <BottomNavigation componentsStateObj={componentsStateObj} />}
      <div style={{ height: isUiPortraitOrientation ? "30vh" : "50vh" }}></div>
    </Container>
  );
};

BuildSection.propTypes = {
  componentsStateObj: PropTypes.object,
  children: PropTypes.node,
};
