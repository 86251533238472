import React from "react";
import ReactDOM from "react-dom";
import ProductBuilder from "./components/ProductBuilder";
import { UrlDataController } from "./components/dataManagers/UrlDataController";
import { HandleGeneralErrors } from "./components/errorHandling/handleErrors";
import EmbedController from "../embed/EmbedController";
import "../globals.scss";

beginExperience();

function beginExperience() {
  if (EmbedController.isEmbedded) {
    document.addEventListener("InitEmbed", reactRender);
  } else if (window.location.href.includes("/live")) {
    window.location = "https://mojodesk.com/products/build-mymojo-3d-custom-desk";
  } else if (window.location.href.includes("/dev")) {
    window.location = "https://mojodesk.com/products/dev-build-mymojo-3d-custom-desk";
  } else {
    reactRender();
  }
}

function reactRender() {
  ReactDOM.render(
    <React.StrictMode>
      <HandleGeneralErrors>
        <UrlDataController>
          <ProductBuilder />
        </UrlDataController>
      </HandleGeneralErrors>
    </React.StrictMode>,
    document.getElementById("react-root")
  );
}
