export const Line = ({ color = "black", height = "2px", width = "80%" }) => {
  return (
    <hr
      className="mx-auto m-0"
      style={{
        color: color,
        backgroundColor: color,
        height: height,
        width: width,
        opacity: 1,
      }}
    />
  );
};
