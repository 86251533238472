/** Returns key/value as componentName: index
 * Used throughout the app to prevent bugs if the componentsState.array were to rearrange
 */

import { useMemo } from "react";

export const useComponentsIndex = (componentsState) => {
  const componentsIndexObj = useMemo(() => {
    return {
      deskModelIndex: componentsState.array.findIndex((component) => component._id === "desk_model"),
      materialsIndex: componentsState.array.findIndex((component) => component._id === "materials"),
      accessoriesIndex: componentsState.array.findIndex((component) => component._id === "accessories"),
    };
  }, [componentsState.array]);

  return componentsIndexObj;
};
