import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring";
import EmbedController from "../../../../embed/EmbedController";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { Boop } from "../Animations/Boop";
import "./styles.scss";

export const RectangleButton = ({ label, iconSrc = null, buttonType, buttonMode = "normal", isActive = false, customClass, onClickHandler, gaObj = null }) => {
  const [styles, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } }));

  const handleClick = () => {
    onClickHandler();
    pushGTMAnalyticsEvent(gaObj);
  };

  return (
    <animated.div style={styles}>
      <Boop boopType="scale" scale="1.04" timing={200} padding={false}>
        <button
          onClick={handleClick}
          onTouchStart={(e) => {
            e.preventDefault();
            handleClick();
          }}
          className={`${buttonType} ${customClass} ${buttonMode === "containsActiveState" && !isActive && "disabled"} py-1 px-2 rectButton`}
        >
          {label}
          {iconSrc && buttonImageFactory(iconSrc, isActive, buttonMode)}
        </button>
      </Boop>
    </animated.div>
  );
};

const buttonImageFactory = (iconSrc, isActive, buttonMode) => {
  switch (buttonMode) {
    case "normal":
      return iconSrc && <img alt="button image" src={iconSrc} />;
      break;

    case "containsActiveState":
      // display check mark
      return iconSrc && isActive && <img alt="button image" src={iconSrc} />;
    default:
      break;
  }
};

RectangleButton.propTypes = {
  label: PropTypes.string,
  iconSrc: PropTypes.string,
  buttonType: PropTypes.oneOf(["default", "outline"]),
  buttonMode: PropTypes.oneOf(["normal", "containsActiveState"]),
  isActive: PropTypes.bool,
  onClickHandler: PropTypes.func,
  gaKey: PropTypes.string,
};
