import { Line } from "../Line/Line";

export const DeskModelSection = ({ children, title, description }) => {
  return (
    <div className="my-3">
      <div className="d-flex flex-column text-center mb-3">
        <h6 className="mb-1">{title}</h6>
        <Line color="black" height="1px" width="30px" />
        {description &&
          <h6 className="mt-1 section-description"><img src="/images/iconInfo.svg" alt="info icon" /> {description}</h6>
        }
      </div>

      {/* desk cards */}
      {children}
    </div>
  );
};
