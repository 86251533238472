import { useAtomValue } from "jotai";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { animated, useTransition } from "react-spring";
import { getCardLayoutInfo } from "../../../modules/getCardLayoutInfo";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { all_sections_array } from "../../dataManagers/GlobalDataManagers";
import { Boop } from "../Animations/Boop";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import "./styles.scss";

export const CardElementAccessories = ({ itemsStateObj, accessoriesArray }) => {
  const [show, setShow] = useState(false);
  const sectionsArray = useAtomValue(all_sections_array);
  const [modalDetails, setModalDetails] = useState({});
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const cardLayoutInfo = getCardLayoutInfo(3);

  const dataToDisplayTransitionArray = useTransition(accessoriesArray, {
    trail: 100,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  const removeItemFromActiveIdArray = (copyActiveIds, itemToRemove) => {
    const itemToRemoveIndex = copyActiveIds.accessories.array.map((object) => object._id)?.indexOf(itemToRemove);
    copyActiveIds.accessories.array.splice(itemToRemoveIndex, 1);

    pushGTMAnalyticsEvent({
      event: "Accessories Click - MojoDesk",
      accessoryClickAction: "removed",
      accessoryType: itemToRemove.includes("monitor") ? "Monitor" : "Accessory",
      id: itemToRemove,
      page: "accessories",
    });

    return copyActiveIds;
  };

  const addOrRemoveAccessory = (copyActiveIds, cardId) => {
    // check if item is already in the activeIds
    if (itemsStateObj.state.activeIds.accessories.array.find((item) => item._id === cardId)) {
      return removeItemFromActiveIdArray(copyActiveIds, cardId);
    }

    copyActiveIds.accessories.array.push({ _id: cardId });

    pushGTMAnalyticsEvent({
      event: "Accessories Click - MojoDesk",
      accessoryClickAction: "added",
      accessoryType: "Accessory",
      id: cardId,
      page: "accessories",
    });

    return copyActiveIds;
  };

  const addOrRemoveMonitor = (copyActiveIds, cardId) => {
    const activeObj = itemsStateObj.state.activeObjs.desk_model._id;
    const monitorSection = sectionsArray.find((section) => section._id === "monitors");
    // check if a monitor is already active
    const monitorFound = itemsStateObj.state.activeObjs.accessories.array.find((item) => item.section === "monitors");

    // check if card clicked was the active card
    if (monitorFound?._id === cardId && !monitorSection?.required?.includes(activeObj)) {
      return removeItemFromActiveIdArray(copyActiveIds, monitorFound._id);
    }

    // check if a monitor is already active
    if (monitorFound) {
      copyActiveIds = removeItemFromActiveIdArray(copyActiveIds, monitorFound._id);
    }

    copyActiveIds.accessories.array.push({ _id: cardId });

    pushGTMAnalyticsEvent({
      event: "Accessories Click - MojoDesk",
      accessoryClickAction: "added",
      accessoryType: "Monitor",
      id: cardId,
      page: "accessories",
    });

    return copyActiveIds;
  };

  const handleCardClick = (e, clickedCard) => {
    // prevents card from firing when details button clicked
    if (e.target.id === "modalButton" || clickedCard?.metaData?.isIncluded) return;

    let copyActiveIds = { ...itemsStateObj.state.activeIds };

    copyActiveIds =
      clickedCard.section === "desk_accessories" ? addOrRemoveAccessory(copyActiveIds, clickedCard._id) : addOrRemoveMonitor(copyActiveIds, clickedCard._id);

    itemsStateObj.setState(copyActiveIds);
  };

  const ProductDetailsModal = ({ show, modalDetails }) => (
    <Modal className="ProductDetailsModal" show={show} size="md" style={{ zIndex: 2002 }} onHide={handleCloseModal} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>{modalDetails.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          {modalDetails?.youtubeSrc && (
            <iframe
              className="mx-auto my-4"
              width="85%"
              height="250"
              src={modalDetails.youtubeSrc}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          )}
          {modalDetails?.image && (
            <ImgWithFallback src={modalDetails.image} fallback={`${modalDetails.image.split(".")[0]}.png`} alt={modalDetails.title} className="mx-auto my-4" />
          )}
          <h5 className="mb-2">Description</h5>
          <p className="description">{modalDetails.description}</p>
          <a href={modalDetails.productPage} target="_blank" rel="noopener noreferrer" className="btn btn-primary mx-auto mt-1 mt-md-4">
            Learn More
          </a>
        </div>
      </Modal.Body>
    </Modal>
  );

  const handleSeeProductDetailsClick = (e, _modalDetails) => {
    setModalDetails({ ..._modalDetails });
    handleShowModal();
    // if(EmbedController.isEmbedded) EmbedController.sendGTMAnalyticsEvent({ 'event': 'Product Details Click - MGP' });
  };

  return (
    <Row className="CardElementAccessories">
      {dataToDisplayTransitionArray((style, card, key, index) => {
        if (card.excluded) return null;
        const isActive = itemsStateObj.state.activeIds.accessories.array.find((item) => item._id === card._id && !card?.metaData?.isIncluded) ? true : false;
        return (
          <Col xs={cardLayoutInfo.xs} md={cardLayoutInfo.md} key={card._id} className="px-2">
            <animated.div style={style}>
              <Card className={`mb-3 itemsCard ${isActive ? "active" : ""}`} style={{ width: cardLayoutInfo.width }} onClick={(e) => handleCardClick(e, card)}>
                <div className="d-flex">
                  {isActive ? <img className="checkMark" alt="check mark" src="/images/checkMark.svg" /> : null}
                  {!card?.metaData?.isIncluded && <div className="price ms-auto px-2">{`$${card.price}`}</div>}
                </div>
                <div className="my-2 d-flex mx-auto">
                  <ImgWithFallback src={card.thumbnail[0]} fallback={card.thumbnail[1] || card.thumbnail[0]} alt={card.displayName} className="card-img-top" />
                </div>
                <Card.Body className="py-1 d-flex flex-column justify-content-center align-items-center">
                  <h6 className="title">{card.displayName} </h6>
                  {card?.description && <p className="description mb-1">{card.description} </p>}
                  {card?.metaData?.isIncluded && <p className="fw-bold">Included</p>}
                  {card?.modalDetails && (
                    <div className="d-flex justify-content-center my-1">
                      <Boop boopType="scale" scale={1.03} timing={200}>
                        <button id="modalButton" onClick={(e) => handleSeeProductDetailsClick(e, card.modalDetails)}>
                          more info
                        </button>
                      </Boop>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </animated.div>
          </Col>
        );
      })}
      <ProductDetailsModal show={show} modalDetails={modalDetails} />
    </Row>
  );
};
