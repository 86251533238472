import { BuildSection } from "../BuildSection/BuildSection";
import { SceneLoadingScreen } from "../SceneLoadingScreen/SceneLoadingScreen";
import { SceneLoadingScreenTransparent } from "../SceneLoadingScreenTransparent/SceneLoadingScreenTransparent";
import { HUD } from "../HUD/HUD";
import Container from "react-bootstrap/Container";
import { HeadersBuildSection } from "../BuildSection/HeadersBuildSection";
import Stepper from "../CustomStepper/CustomStepper";
import "./styles.scss";
import { isMobile } from "react-device-detect";
import { useAtom } from "jotai";
import {
  products_state,
  update_products_activeId,
  components_state,
  update_components_activeId,
  items_state,
  update_items_activeIds,
  desk_animation_state,
  desk_height_state,
  size_overlay_state,
} from "../../dataManagers/GlobalDataManagers";
import { CreateScreens } from "../Screens/CreateScreens";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { usePrevious } from "../../../hooks/usePrevious";
import { Navigation } from "../Navigation/Navigation";
import { WidgetsContainer } from "../Widgets/WidgetsContainer";
import { DeskHeightController } from "../DeskHeightController/DeskHeightController";
import { WidgetMeasurements } from "../Widgets/WidgetMeasurements";
import { WidgetShare } from "../Widgets/WidgetShare";
import { ChangeEnvironment } from "../ChangeEnvironment/ChangeEnvironment";
import ControlsIndicator from "../ControlsIndicator/ControlsIndicator";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export const BuilderUI = () => {
  // products
  const [productsState] = useAtom(products_state);
  const [, setProductsActiveId] = useAtom(update_products_activeId);

  // components
  const [componentsState] = useAtom(components_state);
  const [, setComponentsActiveId] = useAtom(update_components_activeId);
  const [, setComponentsState] = useAtom(components_state);

  // items
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);

  // desk animation
  const [deskAnimationState, setDeskAnimationState] = useAtom(desk_animation_state);
  const [deskHeightState, setDeskHeightState] = useAtom(desk_height_state);

  // size overlay
  const [sizeOverlayState, setSizeOverlayState] = useAtom(size_overlay_state);

  const previousComponentActiveId = usePrevious(componentsState.activeId);

  // when viewport is in landscape and up to 900px wide the widgets cover the top row of the screen
  const matchesBreakPoint = useMediaQuery(`(max-width:900px) and (orientation: landscape)`, { noSsr: true }); // returns bool

  return (
    <>
      <Container fluid className="BuilderUI p-0">
        {/* UI section on left (desktop) or bottom (mobile) of screen */}
        {productsState.isPrimed && componentsState.isPrimed && itemsState.isPrimed && (
          <BuildSection componentsStateObj={{ state: componentsState, setActiveId: setComponentsActiveId }}>
            <HeadersBuildSection headerLabel={productsState.activeObj.displayName} headerLineImgSrc="/images/mojoDeskHeaderLine.svg" />

            {componentsState.activeId !== "shopping_cart" && (
              <>
                <Navigation componentsStateObj={{ state: componentsState, setActiveId: setComponentsActiveId }} />
                <Stepper componentsStateObj={{ state: componentsState, setActiveId: setComponentsActiveId }} />
              </>
            )}

            <CreateScreens
              productsState={productsState}
              componentsStateObj={{ state: componentsState, setState: setComponentsState, setActiveId: setComponentsActiveId }}
              itemsStateObj={{ state: itemsState, setState: setItemsActiveIds }}
              previousComponentsState={previousComponentActiveId}
            />
          </BuildSection>
        )}

        {/* UI section overlaying the 3D scene */}
        {/* Uses .shared-scene-sizing to mimic the scene's canvas. */}
        <HUD>
          {/* loading screen used for the initial load - it covers the scene's canvas */}
          <SceneLoadingScreen />
          {/* transparent loader for when assets are loading dynamically but we don't need to cover up whole screen */}
          <SceneLoadingScreenTransparent ignoreLoadingManager={true} />

          {productsState.isPrimed && componentsState.isPrimed && itemsState.isPrimed && (
            <>
              {/* popup to inform the user to grab the scene to move camera or platform */}
              <ControlsIndicator />
              <WidgetsContainer>
                <div className="float-left d-inline-flex">
                  {componentsState.activeId !== "shopping_cart" && (
                    <RectangleButton
                      label="Add To Cart"
                      buttonType="default"
                      customClass="top-0"
                      onClickHandler={() => setComponentsActiveId("shopping_cart")}
                      gaObj={{ event: "Add To Cart - MojoDesk", page: componentsState.activeId }}
                    />
                  )}
                  {!isMobile && (
                    <>
                      <WidgetMeasurements sizeOverlayStateObj={{ state: sizeOverlayState, setState: setSizeOverlayState }} />
                      <WidgetShare page={componentsState.activeId.split("_")[0]} />
                    </>
                  )}
                </div>
                <div className="float-right d-inline-flex me-2">
                  {isMobile && (
                    <>
                      <WidgetMeasurements sizeOverlayStateObj={{ state: sizeOverlayState, setState: setSizeOverlayState }} />
                      <WidgetShare page={componentsState.activeId.split("_")[0]} />
                    </>
                  )}
                  <ChangeEnvironment itemsStateObj={{ state: itemsState, setState: setItemsActiveIds }} matchesBreakPoint={matchesBreakPoint} />
                  {!isMobile && !matchesBreakPoint && (
                    <DeskHeightController
                      deskAnimationStateObj={{ state: deskAnimationState, setState: setDeskAnimationState }}
                      deskHeightStateObj={{ state: deskHeightState, setState: setDeskHeightState }}
                    />
                  )}
                </div>
              </WidgetsContainer>
            </>
          )}
        </HUD>
      </Container>
    </>
  );
};
