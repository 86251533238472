import { useAtomValue } from "jotai";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTransition, animated } from "react-spring";
import PropTypes from "prop-types";
import { getCardLayoutInfo } from "../../../modules/getCardLayoutInfo";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import { items_state } from "../../dataManagers/GlobalDataManagers";
import "./styles.scss";

export const CardElementCart = ({ cardContentArray, imgFrame = true, imgFrameColor = "#E9EDF0" }) => {
  const itemsState = useAtomValue(items_state);

  console.log("cardContentArray", cardContentArray);
  const cardLayoutInfo = getCardLayoutInfo(2);

  // returns an array with animation style data on each item
  const dataToDisplayTransitionArray = useTransition(cardContentArray, {
    trail: 100,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  return (
    <Row>
      {dataToDisplayTransitionArray((style, card) => {
        let name = card.name;
        if (name === "Bundle") {
          const item = itemsState.array.find((item) => item._id === card.id);
          name = item.title;
        }
        return (
          <Col xs={cardLayoutInfo.xs} md={cardLayoutInfo.md} key={card.id}>
            <animated.div style={style}>
              <Card
                className={`cartCard mx-2 mb-3 ${card.className}`}
                style={{
                  width: cardLayoutInfo.width,
                  color: card?.customData?.cardColor ? "#fafafa" : "black",
                }}
              >
                <div className="topBorderColor d-flex">
                  {card?.isIncluded && (
                    <div className="me-auto">
                      <img alt="new" className="cardBadge" src="/images/badgeComesWith.png" />
                    </div>
                  )}
                </div>
                <div className={`d-flex mx-auto my-3 imgContainer ${imgFrame ? "imgFrame" : ""}`} style={{ backgroundColor: imgFrame && imgFrameColor }}>
                  {card?.previewImg && (
                    <ImgWithFallback
                      src={card.previewImg[0]}
                      fallback={card.previewImg[1] || card.previewImg[0]}
                      alt={card.title}
                      className="mx-auto productImage"
                    />
                  )}
                  {card?.previewText && <h5 className="mx-auto mt-3">{card.previewText}</h5>}
                </div>
                <Card.Body className="py-2 d-flex flex-column">
                  <Card.Title className="mt-auto">{card.title}</Card.Title>
                  {name && <p>{name}</p>}
                  {card?.itemDescription && <p>{card.itemDescription}</p>}
                  {card.price !== 0 && !card.isIncluded && <p className="price mb-0">{`$${card.price}`}</p>}
                </Card.Body>
              </Card>
            </animated.div>
          </Col>
        );
      })}
    </Row>
  );
};

CardElementCart.propTypes = {
  /**
   * Contains card content and activeIndex
   */
  cardContentArray: PropTypes.array,
  /**
   * Whether you want white background behind the image
   */
  imgFrame: PropTypes.bool,
  /**
   * Color of the image background
   */
  imgFrameColor: PropTypes.string,
};
