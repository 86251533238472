import { useMemo } from "react";
import sift from "sift";
import { CircleButton } from "../CircleButton/CircleButton";
import { Boop } from "../Animations/Boop";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const WidgetChangeEnv = ({ itemsStateObj }) => {
  const environmentsArray = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ section: { $eq: "environment_state" } }));
  }, []);

  // content to show in overlay on btn click
  const tooltipContent = {
    id: "change-env-tooltip",
    onHover: "Change Room",
    placement: "bottom",
    defaultShow: false,
  };

  // switch environment
  const handleClick = () => {
    const environmentIndex = environmentsArray.findIndex(
      (environmentObj) => environmentObj._id === itemsStateObj.state.activeIds.non_items.environment_state._id
    );
    let copy = { ...itemsStateObj.state.activeIds };
    // set the environment to the next one in the array
    const nextEnvironmentIndex = environmentIndex + 1 === environmentsArray.length ? 0 : environmentIndex + 1;
    copy.non_items.environment_state = { _id: environmentsArray[nextEnvironmentIndex]._id };

    itemsStateObj.setState(copy);

    pushGTMAnalyticsEvent({ event: "Room Changed - MojoDesk", activeRoom: environmentsArray[nextEnvironmentIndex]._id, device: "mobile" });
  };

  return (
    <Boop boopType="scale" scale={1.04} timing={200}>
      <div onClick={() => handleClick()} className="changeEnvButton mx-1">
        <CircleButton circleColor="#FFFFFF" circleSize="40px" tooltipContent={tooltipContent} icon="/images/iconChangeEnv.png" />
      </div>
    </Boop>
  );
};
